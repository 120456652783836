

import React, { useContext, useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie'
import axios from 'axios';
// import {Select} from "antd"
import Select from 'react-select'





const { Option } = Select;


function SearchDocument(prop) {
  const [title, setTitle] = useState("")
  const [mFilesVal, setMfilesVal] = useState({ fileUrl: "", name: "" })
  const [mFilesArray, setMFilesArray] = useState([])
  const [mapVal, setMapVal] = useState([])
  let [count, setCount] = useState(0)
  const [imageHolder, setImageHolder] = useState({ name: "", doclink: "" })
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState('')


  let userId = Cookies.get('userId');


  const handleFile = (doc) =>{
    // eslint-disable-next-line no-unused-expressions
    prop?.handleDocAdd({
      key: count++,
      docTitle: doc.label,
      docPath: doc.value
    })


    setCount(count)
  }

  const handleImage = (name, doclink) => {

    setImageHolder(

      {
        name: name,
        doclink: doclink
      }
    )

  }



  const change = (e) => {
    console.log(e)
    setTitle(e)
  }
  useEffect(() => {
    console.log(userId)
    console.log(title)
    searchDocs(userId, title)

  }, [title])

  const searchDocs = () => {




    const options = {
      url: `https://erpapi.edostate.gov.ng/services/api/dashboard/searchdocument?user_id=${userId}&DocTitle=${title}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        crossorigin: true,
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*',
      },
    };
    // console.log(options);
    return axios(options)
      .then((res) => {
        console.log(res);
      Array.isArray(res.data) ?  setOptions(res.data?.map((item) =>({
          value: item.fileurl, label: item.title
        }))) : console.log('ok')
       
         setMapVal(res.data.isArray && res?.data?.map((tem) =>
          <Option

            value={tem.title}
            key={tem.fileurl}
          >
            {tem.title}
          </Option>
    
        ))
        
        return { options }
        // console.log(a)


      })

  }







  return (
    <div style={{ width: "100%" }}>

      {/* <Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Select a file"
        optionFilterProp="children"
        onSearch={(value) => { change(value) }}
        value={imageHolder.name}

        onChange={(text, index) => {
          // handleImage(index.value, index.key)


           // eslint-disable-next-line no-unused-expressions
           prop?.handleDocAdd({
            key: count++,
            name: index.value,
            image: index.key
          })


          setCount(count)

          // setMfilesVal({
          //   name: index.value,
          //   fileUrl: index.key
          // })

          // handleImage('', '')

        }

        }
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {mapVal}
      </Select> */}


{/* <Select
    
        loadOptions={searchDocs}
        onInputChange={(value) => setTitle(value)}
        getOptionLabel={(e) => e.title}
        getOptionValue={(e) => e.fileurl}
        onChange={(value) => {
        


         console.log(value)

            // eslint-disable-next-line no-unused-expressions
            // prop?.handleDocAdd({
            //   key: count++,
            //   docTitle: index.value,
            //   docPath: index.key
            // })
  
  
            // setCount(count)


        }}
      /> */}
      <Select
            className="w-100"
            name="form-field-name"
            value={title}
            options={options}
            onChange={(e) => handleFile(e)}
            onInputChange = {e => setTitle(e)}
      />









    </div>
  );
}

export default SearchDocument;



