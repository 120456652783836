import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import { Table, Input, InputNumber, Popconfirm, Form, Select,Button, Collapse, Divider } from 'antd';
import { DeleteOutlined, UploadOutlined, CheckCircleOutlined} from '@ant-design/icons';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import coatOfArm from "../../assets/images/coat-of-arm.png";


const Page12Cards = (prop) => {
   const location = useLocation();

   const currentPath = location.pathname
   const [small, setSmall] = useState(421)
  const [smallMid, setSmallMid] = useState(640)
  const [mid, setMid] = useState(1007)
  const [showBar, setshowBar] = useState(false)
  // const [showBar, setshowBar] = useState(false)

  const [size, setSize] = useState(600)

  useEffect(()=>{
setSize(window.innerWidth)
  },[])
   
  return (
      
    <div className=" border w-100 point" style={{width:"32.5%", backgroundColor:"transparent", margin:"0 2.5px"}} >
{
  (currentPath === '/Agents-Dashboard/enrolment') ?
  <div style ={{borderBottom:"3px solid green", }} >
    <div style={{backgroundColor:(prop.color==true)?'lightgreen':"white"}}>
      
      <div  style={{padding:(size< small)?'5px':"15px 15px",marginBottom:"3px", backgroundColor:(prop.color==true)?'lightgreen':"white", display:'flex', justifyContent:'center', alignItems:'center'}}>
      	<h2>{prop.cardTitle}</h2>
      	{prop.image}
      </div>
      
    </div>
</div> :
  <div style ={{borderBottom:"3px solid green", }} >
  <div style={{backgroundColor:(prop.color==true)?'lightgreen':"white"}}>
    
    <div className="flex-between" style={{padding:(size< small)?'5px':"10px 15px",marginBottom:(size< small)?'0px':"3px", backgroundColor:(prop.color==true)?'lightgreen':"white"}}>
      <p>{prop.cardTitle}</p>
      {prop.image}
    </div>
    <h2 style={{padding:"0 15px"}}><b>{(prop.count)?prop.count:"0"}</b></h2>
  </div>
</div>

}

{/* <div style={{backgroundColor:"white"}}>
    <ul style={{listStyle:"none", padding:"0 15px", backgroundColor:"white"}}>
        <li className="border padding flex-between" style={{borderLeft:"none", borderRight:"none"}}><div>{prop.text}</div><div>{prop.data?.initiated||prop.data?.ongoing }</div></li>
        <li className="border padding flex-between" style={{borderLeft:"none", borderRight:"none"}} ><div>{prop.text2}</div><div>{prop.data?.pending ||prop.data?.completed}</div></li>
        <li className="border padding flex-between" style={{borderLeft:"none", borderRight:"none"}}><div>{prop.text3}</div><div>{prop.data?.approved?? 0}</div></li>
        {prop.text4 &&<li className="border padding flex-between" style={{borderLeft:"none", borderRight:"none"}}><div>{prop.text4}</div><div>{prop.data?.declined ?? 0}</div></li>}
    </ul>
</div> */}
    </div>

   
  );
};
export default Page12Cards;
