import { Button } from "antd";
import Cookies from "js-cookie";
import React from "react";
import TextareaInput from "../inputs/TextareaInput";
import avatar from "../../assets/images/user-avater.png"


const Message =()=>{


    return(
        <>
        <div className ="white-color">
            <div className = 'flex space-between p-20 flex-v-center'>
                <div className='p-10'>
                <Button className='primary-color '>
                    Send Personal Message
                </Button>
                </div>
                

                <div className='flex right-text'>
                    <div className='p-10'>
                        <p>
                            {Cookies.get("fullName")}
                        </p>
                        <h6>
                            {Cookies.get("role")}
                        </h6>
                    </div>
                    <div className='p-10 img'>
                        <img src={avatar} className="user-avater-details h-100" alt="user-avater.png" />
                    </div>
                </div>

            </div>
            <hr />

            <TextareaInput className='p-20' rows={15}/>
        </div>

        <button
                        className="btn btn-large p-r m-t-20 w-100 flex  flex-v-center flex-h-center justify-content-center"
                        style={{ textAlign: "center", height: "43px" }}
                        type="button"
                        disabled={false}>
                        <p style={{ margin: "auto 15px auto" }}>{"Submit Request"}</p>
                        {/* {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />} */}
                    </button>
</>

    )
}

export default Message;