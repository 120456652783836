import React from "react";
import TextInput from "../inputs/TextInput";



const Performance = (props) => {
    return (
        <div className='flex flex-direction-column white-color m-t-20 p-20'>
            <p>Overall Appraisal Highlight</p>
            <div className='flex'>
                <div className='flex space-between w-100'>
                    <TextInput readOnly value='Period In View:' className='inputs inline-block w-30' />
                    <TextInput readOnly value={props.department || 'Start Date'} className='inputs inline-block w-15' />
                    <TextInput readOnly value={props.department || 'End Date'} className='inputs inline-block w-15' />
                    <TextInput readOnly value={props.department || 'Current Evaluation'} className='inputs inline-block w-20' />
                </div>
            </div>

            <div className='flex '>


                <div className="table-view m-t-10 w-95">
                    <div className="table-headers">
                        <div className="table-row">
                            <div className="th w-40"><p className="san-sherif">Commitments:</p></div>
                            <div className="th w-10"><h5 className="san-sherif">Weight</h5></div>
                            <div className="th w-10"><h5 className="san-sherif">Mid Year(.../100)</h5></div>
                            <div className="th w-10"><h5 className="san-sherif">Supervisor's Mid Year</h5></div>
                            <div className="th w-10"><h5 className="san-sherif">Full year(.../100)</h5></div>
                            <div className="th w-10"><h5 className="san-sherif">Supervisor's Full Year</h5></div>
                            <div className="th w-10">

                            </div>
                        </div>
                    </div>
                    <div className="table-body">
                        {props.performance.map((item) => (
                            <div className="table-row" key={item.id}>
                                <h5 className="td w-50">{item.objective}</h5>
                                <h5 className="td">{`${item.weight}%`}</h5>
                                <h5 className="td">{item.midYearScore}</h5>
                                <h5 className="td">{item.supervisorMidYearScore}</h5>
                                <h5 className="td">{item.fullYearScore}</h5>
                                <h5 className="td">{item.supervisorFullYearScore}</h5>
                                <h5 className="td actions">

                                </h5>
                            </div>
                        ))}
                    </div>
                </div>


                <div className='flex flex-direction-column space-between m-30 center-text'>

                    <div>
                        <h1>
                            75%
                        </h1>
                        <h5>
                            Job Performance
                        </h5>
                    </div>
                    <div>
                        <h1>
                            93%
                        </h1>
                        <h5>
                            Competency Assessment
                        </h5>
                    </div>
                    <div>
                        <h1>
                            88%
                        </h1>
                        <h5>
                            Outstanding
                        </h5>
                    </div>

                </div>

                

            </div>



{/* <div className='' style={{backgroundColor:"lightgrey", padding:"20px"}}>
<span>View Performance Details |</span> <span>View Past Appraisal Records</span>
</div> */}
            

        </div>
    )
}

export default Performance;