/* eslint-disable no-unused-vars */
import React from "react";
import { IoMdClose } from "react-icons/io";
import Table from "./Table";
import parser from "html-react-parser";
import DocTable from "./DocTable";
import { PDFExport } from "@progress/kendo-react-pdf";
import edologo from "../../assets/images/edo-logo-250.png";
import { numberToWords, stripCommas } from "../../utility/general";


const ViewDetailsReleaseWarrant = ({
  details,
  closeModal,
  expenseItems,
  supportingDoc,
  expenseQuery,
  approvers,
  ...props
}) => {
  const pdfExportComponent = React.useRef(null);

  console.log(numberToWords(expenseItems[0]?.amount));
  console.log(details);
  console.log(stripCommas("123,456,789.00"));
  console.log(expenseItems);

  const downloadPdf = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  return (
    <div className="overlay box-shadow flex-h-center flex-v-center ">
      <div className="flex flex-v-center bg-transparent flex-h-center flex-direction-column m-b-20">
        <div
          className="modal-box bg-transparent box-shadow"
          style={{
            position: "relative",
            width: "1000px",
          }}
        >
          <button className="btn w-20 p-10 m-b-10" onClick={downloadPdf}>
            Print Memo PDF
          </button>
          <div className="content no-padding w-100">
            <IoMdClose
              className="pointer m-l-5"
              style={{
                position: "absolute",
                top: "0",
                left: "100%",
                fontSize: "24px",
                color: "#fff",
                width: "40px",
                height: "40px",
                backgroundColor: "#F74F11",
                padding: "3px",
              }}
              onClick={() => {
                closeModal();
              }}
            />
            <PDFExport ref={pdfExportComponent}>
              <div className="w-" style={{ width: "1000px" }}>
                <div className="w-100 bg-white ">
                  <div
                    className="w-100"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      gap: "16px",
                      padding: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ height: "50px" }}
                      src={edologo}
                      alt=""
                      srcset=""
                    />

                    {/* <img
                      style={{ width: "50px" }}
                      src={festaclogo}
                      alt=""
                      srcset=""
                    /> */}
                  </div>
                  <p
                    className="bold-text text-center sixteen"
                    style={{
                      fontStyle: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    {/* {details.mda?.toUpperCase()} */}
                    MINISTRY OF BUDGET AND ECONOMIC PLANNING <br />
                    BLOCK, B SECRETARIAT COMPLEX,
                    <br />
                    SAPELE ROAD,
                    <br />
                    BENIN CITY
                    <br />
                    EDO STATE OF NIGERIA
                  </p>

                  <div
                    className="bold-text "
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontStyle: "bold",
                      fontSize: "14px",
                      marginLeft: "3%",
                      marginTop: "40px",
                    }}
                  >
                    The Accountant General, <br />
                    UFS: Honourable Commisioner,
                    <br />
                    Ministry of Budget & Economic Planning.
                    <br />
                    Benin City,
                    <br />
                    Edo State of Nigeria."
                  </div>
                  <div className="flex flex-direction-column">
                    <div
                      className="bold-text"
                      style={{
                        padding: "15px",
                        backgroundColor: "#d5ffd5",
                        fontSize: "20px",
                        fontStyle: "bold",
                        textAlign: "center",
                        marginTop: "40px",
                      }}
                    >
                      {/* Expense Details */}
                      {/* Subject: {details.title?.toUpperCase()} */}
                      EXPENDITURE RELEASE WARRANT
                    </div>
                  </div>
                </div>
                <div className="w-100 bg-white pd-10">
                  <div className="flex flex-direction-column">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "16px",
                        padding: "3%",
                      }}
                    >
                      {/* <div className="flex  p-5 pd-b-5">
                        <div className=" no-padding no-margin bold-text sixteen">
                          Title:{" "}
                        </div>
                        <div className="w-80 bold-text sixteen">{details.title} </div>
                      </div> */}
                      You are hereby authorised and required to issue from the
                      CAPITAL DEVELOPMENT FUND to the credit of the Drawing
                      Accounts of the Ministry (ies)/ Department (s)/ Agency
                      (ies) of Government listed in the Schedule below, a sum
                      not exceeding {numberToWords(parseInt(stripCommas(details?.amount))).toUpperCase()} to be applied and expended
                      for the services set forth in the schedule and in
                      accordance with the details under the Heads/Sub-heads of
                      the Estimates specified.
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        fontStyle: "bold",
                        fontSize: "14px",
                        marginLeft: "3%",
                        marginTop: "40px",
                      }}
                    >
                      <p>
                        {" "}
                        Copy: <br />
                      </p>
                      <div
                        className="bold-text "
                        style={{
                          textAlign: "left",
                          fontStyle: "bold",
                          fontSize: "14px",
                        }}
                      >
                        The Auditor General (State) <br />
                        Benin City <br />
                        Edo State
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-100 bg-white "></div>
                <div className="w-100 bg-white  ">
                  <div className="flex flex-direction-column">
                    <div className="flex flex-direction-column">
                      <div
                        style={{
                          padding: "15px",
                          backgroundColor: "#43425D",
                          color: "#fff",
                        }}
                      >
                        <p
                          className="bold-text"
                          style={{
                            textAlign: "center",
                            fontStyle: "bold",
                            fontSize: "16px",
                          }}
                        >
                          SCHEDULE OF PROVISION AUTHORISED ON FUND RELEASE
                        </p>
                      </div>
                    </div>
                    <Table
                      header={[
                        "Economic Code",
                        "Programme Code",
                        "Details Of Expenditure",
                        "Benefitting Ministry",
                        "Authorised Provision Including Suplementary",
                        "Amount Already Released",
                        "Balance on account Before this release",
                        "Amount to be released",
                        "Balance on Account after release",
                        // "Amount",
                      ]}
                      tableBody={expenseItems}
                      details={details}
                      propToUse={[
                        "budgetItemId",
                        "budgetItemId",
                        "description",
                        "mda",
                        "authorizedProvision",
                        "amountAlreadyReleased",
                        "balanceBeforeRelease",
                        // "balanceToBeReleased",
                        "amt",
                        "balanceAfterRelease",
                        // "quantity",
                        // "amt",
                      ]}
                    />
                  </div>
                </div>
                <div className="w-100 bg-white  ">
                  <div className="flex flex-direction-column">
                    <DocTable
                      headers={["Supporting Document"]}
                      tableBody={supportingDoc}
                      action={false}
                    />
                  </div>
                </div>
                {/* <div className="w-100 bg-white  ">
                  <div className="flex flex-direction-column">
                    <div
                      style={{
                        padding: "15px",
                        backgroundColor: "#43425D",
                        color: "#fff",
                      }}
                    >
                      Expense Query Table
                    </div>
                    <Table
                      header={[
                        "Sender",
                        "Message",
                        "Date Sent",
                        // 'Status',
                        "Response Message",
                        "Date Replied",
                      ]}
                      tableBody={expenseQuery}
                      propToUse={[
                        "sender",
                        "message",
                        "dateSent",
                        // 'status',
                        "responseMessage",
                        "dateReplied",
                      ]}
                    />
                  </div>
                </div> */}

                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "flex-end",
                    width:"100%"
                  }}
                >
                 
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{ width: "80px", justifyItems: "center" }}
                      src={`data:image/png;base64, ${details?.commisionerSignature64}`}
                    />
                    <p>Hon. Commissioner</p>
                    <p>Ministry of Budget & Economic Planning.</p>
                  </div>
                </div>
              </div>
            </PDFExport>
          </div>
          <div className="flex ">
            {props.manageRequest && props?.btnStatus === "Pending" && (
              <div>
                <button
                  className="btn m-r-10"
                  style={{ width: `${props.btnWidth || "105px"}` }}
                  onClick={() => {
                    props?.setStatus("Approve");
                    // props?.setShowAuthModal(true);
                    props?.setAuth(true);
                    props?.setId([]);
                    props?.setShowCommentModal(true);
                    props?.setCurrentItem(details);
                    props?.setSingle(true);
                    props?.setBtnText("Approve");
                  }}
                >
                  {props?.btnText || "Approve"}
                </button>

                <button
                  className="btn m-r-10"
                  style={{
                    backgroundColor: "red",
                    width: `${props.btnWidth || "105px"}`,
                  }}
                  onClick={() => {
                    props?.setStatus("Decline");
                    // props?.setShowAuthModal(true);
                    props?.setAuth(true);
                    props?.setId([]);
                    props?.setShowCommentModal(true);
                    props?.setCurrentItem(details);
                    props?.setSingle(true);
                    props?.setBtnText("Decline");
                  }}
                >
                  {props?.btnText || "Decline"}
                </button>

                <button
                  className="btn m-r-10"
                  style={{
                    backgroundColor: "purple",
                    width: `${props.btnWidth || "105px"}`,
                  }}
                  onClick={() => {
                    props?.setStatus("Inquiry");
                    // props?.setShowAuthModal(true);
                    props?.setAuth(true);
                    props?.setId([]);
                    props?.setShowCommentModal(true);
                    props?.setCurrentItem(details);
                    props?.setSingle(true);
                    props?.setBtnText("Inquiry");
                    props?.Auth && props?.getQueries();
                  }}
                >
                  {props?.btnText || "Send Inquiry"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsReleaseWarrant;
