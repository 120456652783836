import React from "react";
import {Table} from "antd";




const Qualifications = (props) =>{


    const columns = [

        {
          title: 'School Name',
          dataIndex: 'schoolName',
          //   width: '55%',
          editable: false,
        },
        {
          title: 'Course',
          dataIndex: 'course',
          // width: '55%',
          editable: false,
        },
        {
          title: 'Certificate',
          dataIndex: 'certificate',
          // width: '55%',
          editable: false,
        },
        {
          title: 'Class of Degree',
          dataIndex: 'classOfDegree',
        //   width: '30%',
    
        },
        {
          title: 'Graduation Year',
          dataIndex: 'graduateYear',
        //   width: '10%',
    
        }
    ]



    const QualificationData = props.staffQualifications.map((item)=>(
        {
            schoolName:item.schoolName,
            course:item.course,
            certificate:item.certificate,
            classOfDegree:item.classOfDegree,
            graduateYear:item.graduateYear
        }
    ))


    return(
        <div className="p-20 white-color">
          <div className=' primary-color p-l-20 w-100 p-20'>
                    <p>{props.cardTitle || "Personal Qualifications"}</p>
                </div>
            <Table
            bordered
            dataSource={QualificationData}
            columns={columns}
            rowClassName="editable-row"
          />
        </div>
    )
}

export default Qualifications;