import moment from "moment";

export const toggleScroll = () => {
  const overlays = document.querySelectorAll(".overlay");

  if (overlays.length > 0) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
};

export const numberWithCommas = (x) => {
  if (x === '') {
    return
  }
  if (String(x).includes(',')) {
    return String(x)?.replace(/,/g, '')?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  else {
    return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

  }

}

export const convertImgToBase64 = (inputFile) => {
  if (inputFile === undefined) return "";
  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

export const getDeptName = (id) => {
  if (id == 1) {
    return 'DFA'
  }
  if (id == 2) {
    return 'Internal Auditor'
  }
  if (id == 3) {
    return 'Final Account'
  }
  if (id == 5) {
    return 'Accountant General'
  }
  if (id == 4) {
    return 'Expenditure Control'
  }
  if (id == 6) {
    return 'Management Account'
  }
  if (id == 7) {
    return 'Treasury Cash Account'
  }
  if (id == 10) {
    return 'PS'
  }
  if (id == 11) {
    return 'Inspectorate'
  }
  if (id == 12) {
    return 'Governor'
  }
}

export const formatFileUrl = (path) => `${process.env.REACT_APP_BACKEND_URL}/${path}`;

export const getLeaveDays = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  var totalDays = Number(endDate.diff(startDate, "days"));

  if (totalDays === 7) totalDays -= 2;
  else if (totalDays < 7 && startDate.day() + totalDays - 1 > 5) {
    /** Leave days less than a week and falls within the weekend */
    totalDays -= 2;
  } else if (totalDays > 7) {
    /** Leave days greater than a week*/
    // let _floor = Math.floor(totalDays / 7);
    // let _rem = totalDays % 7;
    // totalDays = 7 * _floor - 2 * _floor + _rem;
    totalDays = (totalDays * 5 - (startDate.day() - endDate.day()) * 2) / 7;
  }

  return totalDays + 1;
};

export function timeAgo(dateTime) {
  const now = new Date();
  const diffInSeconds = Math.floor((now - dateTime) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 3600) {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const diffInHours = Math.floor(diffInSeconds / 3600);
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  } else {
    const diffInDays = Math.floor(diffInSeconds / 86400);
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  }
}

export const stripCommas = (val) => {
  let num = String(val).replace(/,/g, '')
  return parseFloat(num);
}

export const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function numberToWords(num) {
  // Handle negative numbers
  if (num < 0) {
    return "Negative " + numberToWords(Math.abs(num));
  }

  // Define an array of number words for units, tens, and hundreds
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

  // Function to convert a three-digit number to words
  function convertThreeDigits(num) {
    const hundreds = Math.floor(num / 100);
    const remaining = num % 100;

    let word = "";
    if (hundreds > 0) {
      word += ones[hundreds] + " hundred ";
    }

    if (remaining > 0) {
      if (remaining < 20) {
        word += ones[remaining];
      } else {
        const tensDigit = Math.floor(remaining / 10);
        const onesDigit = remaining % 10;
        word += tens[tensDigit];
        if (onesDigit > 0) {
          word += " " + ones[onesDigit];
        }
      }
    }

    return word;
  }

  // Separate the number into billions, millions, thousands, dollars, and cents
  const billions = Math.floor(num / 1000000000);
  const millions = Math.floor((num % 1000000000) / 1000000);
  const thousands = Math.floor((num % 1000000) / 1000);
  const dollars = Math.floor(num % 1000);
  const cents = Math.round((num * 100) % 100);

  // Convert each part to words and join them with commas
  let words = "";
  if (billions > 0) {
    words += convertThreeDigits(billions) + " billion ";
  }
  if (millions > 0) {
    words += convertThreeDigits(millions) + " million ";
  }
  if (thousands > 0) {
    words += convertThreeDigits(thousands) + " thousand ";
  }

  if (dollars > 0) {
    words += convertThreeDigits(dollars);
  } else if (words.length === 0) {
    words += "zero";
  }

  words += " naira and ";

  if (cents > 0) {
    words += ones[cents] + " cent";
    if (cents > 1) {
      words += "s";
    }
  } else {
    words += "zero kobo";
  }

  return words.trim();
}
 
