import React, { useEffect, useState } from "react";
import TextInput from '../inputs/TextInput';
import TextAreaInput from '../inputs/TextareaInput';
import SearchDocument from "../layouts/SearchDocument"
import UploadButton from '../layouts/UploadButton';
import Greeting from '../common/Greeting';
import axios from "axios";
import ReactSelect from "react-select";
import { Select } from 'antd';
import Cookies from "js-cookie";
import { Gets, Posts} from "../../utility/fetch";
import {MdCancel} from "react-icons/md"
import Item from "antd/lib/list/Item";



export let Access_token = Cookies.get("token");

const RaiseMemo = () => {
    const [title, setTitle] = useState('');
    const [memoSubject, setMemoSubject] = useState(title);
    const [scheduleToken, setScheduleToken] = useState("")
    const [meetingCategory, setMeetingCategory] = useState([])
    const [meetingCategoryName, setmeetingCategoryName] = useState("");
    const [meetingCategoryId, setmeetingCategoryId] = useState(11);
    const [description, setDescription] = useState('');
    const [employees, setEmployees] = useState([])
    const [vendor, setVendor] = useState('');
    const [dateReceived, setDateReceived] = useState('');
    const [recipient, setRecipient] = useState("");
    const [financialImplication, setFinancialImplication] = useState('');
    const [fileArray, setFileArray] = useState([]);


    const { Option } = Select;

    
    const scheduleLogin = `auth/${Cookies.get("emp")}`


    console.log(Cookies.get("emp"))
    useEffect(() => {
        Gets("", handleToken, scheduleLogin, Access_token)


    }, [])


    const onDelete =(id)=>{
       setFileArray(
        fileArray.filter((item)=>{
            return item.key !== id
        })
       ) 
    }


    const clearForm = () => {
        setDescription("")
        setTitle("")
        setRecipient("")
    }

    const handleSelectedEmployee = (selected) => {
        console.log(selected)
        setRecipient(selected)
    }


    const setTitlehead = (event) => {
        setTitle(event.target.value)
    }


    const uploadFiles =(data)=>{
        console.log(data)
        setFileArray([...fileArray, data])
    }

    const handleMFiles =(data)=>{
        console.log(data)
        console.log(fileArray)
        setFileArray([...fileArray, data])
    }

    const handleCategory = (data) => {
        console.log(data)
        setMeetingCategory(data.data.filter((item) => {
            return item.categoryCode === "Memo"
        }).map((item) => (
            setmeetingCategoryId(item.id),
            setmeetingCategoryName(item.categoryName)
        )))
    }

    console.log(meetingCategoryName, meetingCategoryId, description, vendor, title, recipient)
    const handleToken = (data) => {
        console.log(data)
        setScheduleToken(data.token)
        Gets("", handleCategory, "meeting-category", data.token)
        Gets("", handleEmployee, "employee/profile", data.token)
    }

    const submit = () => {
        console.log(Access_token)
        const datas = {
            title: title,
            description: description,
            vendor: "Governor",
            dateReceived: new Date().toISOString(),
            recipient: parseInt(recipient),
            financialImplication: 0,
            meetingCategoryId,
            documents: fileArray,
        }
        Posts(datas, 'request', handleSubmit, scheduleToken)
    }


    const handleSubmit = (data) => {
        console.log(data)
        clearForm();
        alert(data.message)

    }


    const handleEmployee = (data) => {
        console.log(data)
        setEmployees(data.data.map((item) => (
            {
                key: item.id,
                value: item.id,
                label: `${item.firstName} ${item.lastName}`
            }
        )))
    }




    return (
        <div className='flex flex-direction-column w-80'>
            <div className='m-b-40'>
                <Greeting />
            </div>
            <div className='primary-color p-l-20 w-100 p-20'>
                <p>Memo Subject :  {title}</p>
            </div>
            <div style={{ backgroundColor: 'white' }} className='p-t-40'>
                <div>
                    <span className=' p-l-20 col-2'><b>Subject:</b></span> <TextInput onChange={setTitlehead} value={title} className='inputs inline-block w-80' />
                </div>
                <div>
                    <span className=' p-l-20 col-2'><b>To:</b></span> <Select
                        className='inputs inline-block w-80'
                        showSearch
                        value={recipient}
                        placeholder="Search..."
                        optionFilterProp="children"
                        onChange={handleSelectedEmployee}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {employees.map((item)=>(
                            <Option value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                    {/* <ReactSelect options={employees} onChange={handleSelectedEmployee} className='inputs inline-block w-80' /> */}
                </div>
                <div>
                    <span className=' p-l-20 col-2'><b>Memo Category:</b></span> <TextInput readOnly value={meetingCategoryName} className='inputs inline-block w-80' />
                </div>
                <div>
                    <span className=' p-l-20 col-2'></span> <TextAreaInput onChange={e => setDescription(e.target.value)} value={description} rows={15} className='inputs inline-block w-80' />
                </div>

                <div className='w-40 m-l-20 m-t-20 m-b-20'>
                    <span className=' p-l-20 '><b></b></span> <UploadButton uploadFilesFunc = {uploadFiles} className='p-l-20' />
                </div>
                <div className='w-40 m-l-20 m-b-20'>
                <span className=' p-l-20 '><b></b></span> <SearchDocument handleDocAdd = {handleMFiles}   />
                </div>
                <div className='w-40 m-l-20 '>
                    {
                       Array.isArray(fileArray) && fileArray.map((item)=>(
                            <div key = {item.key }className="flex space-between m-10"> <a href={item.docPath}>{item.docTitle}</a> <MdCancel style={{fontSize:"larger"}} onClick={()=>onDelete(item.key)}/></div>
                        ))
                    }
                </div>

                <div className='flex space-between'>
                    {/* <button
                        className="btn-save btn-large p-r m-20 w-80 flex  flex-v-center flex-h-center justify-content-center"
                        style={{ textAlign: "center", height: "43px" }}
                        type="button"
                        disabled={false}>
                        <p style={{ margin: "auto 15px auto" }}>{"Save Draft"}</p>
                        
                    </button> */}

                    <button
                        className="btn btn-large p-r m-20 w-100 flex  flex-v-center flex-h-center justify-content-center"
                        style={{ textAlign: "center", height: "43px" }}
                        onClick={submit}
                        type="button"
                        disabled={false}>
                        <p style={{ margin: "auto 15px auto" }}>{"Send Message"}</p>
                        {/* {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />} */}
                    </button>
                </div>

            </div>
        </div>
    );
}
export default RaiseMemo;