import React from 'react';
import TextInput from '../inputs/TextInput';




const CardDetails = (props) =>{
    return(
            <div className='flex flex-direction-column w-100'>
                <div className='m-b-20 primary-color p-l-20 w-100 p-20 '>
                    <p className="bold-text">{props.cardTitle || "Osagie's Personal Profile"}</p>
                </div>
                <div className='white-color w-100'>
                    <div className='m-input-5 flex space-between '>
                    <TextInput readOnly placeholder = 'Department:' className='inputs inline-block w-20' />  <TextInput value={props.department || 'Office of the Commisioner for Education'} className='inputs inline-block w-75' />
                    </div>
                    {/* <div className='m-input-5 flex space-between'>
                    <TextInput readOnly placeholder = 'Division:' className='inputs inline-block w-20' /> <TextInput value={props.division || "Commisioner's Office"}  className='inputs inline-block w-75' />
                    </div >
                    <div className='m-input-5 flex space-between'>
                    <TextInput readOnly placeholder = 'Branch:' className='inputs inline-block w-20' /> <TextInput value={props.branch || 'Primary Education Policy & Intervention programmes'}  className='inputs inline-block w-75' />
                    </div>
                    <div className='m-input-5 flex space-between'>
                    <TextInput readOnly placeholder = 'Section:' className='inputs inline-block w-20' /> <TextInput value={props.section || 'Field Counsellor'}  className='inputs inline-block w-75' />
                    </div> */}
                </div>
            </div>

    );
}

export default CardDetails;