import React from "react";
import { Radio, Table } from "antd";

const TrainingDetails = (props) => {

    const [value, setValue] = React.useState(1);


    const numberWithCommas =(x)=> {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const selfColumns = [

        {
            title: 'Certificate',
            dataIndex: 'certType',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'Course',
            dataIndex: 'certObtained',
            // width: '55%',
            editable: false,
        },
        {
            title: 'Certificate',
            dataIndex: 'certYear',
            // width: '55%',
            editable: false,
        },
        {
            title: 'Class of Degree',
            dataIndex: 'certNumber',
            //   width: '30%',

        },
        {
            title: 'Graduation Year',
            dataIndex: 'certExpirationDate',
            //   width: '10%',

        }
    ]


    const selfTraining = props.selfTraining.map((item) => (
        {
            certType: item.certType,
            certObtained: item.certObtained,
            certYear: item.certYear,
            certNumber: item.certNumber,
            certExpirationDate: item.certExpirationDate
        }
    ))





    const approvedColumns = [

        {
            title: 'Title',
            dataIndex: 'title',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'Organizing Body',
            dataIndex: 'organisingBody',
            // width: '55%',
            editable: false,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            // width: '55%',
            editable: false,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            //   width: '30%',

        },
        {
            title: 'Details',
            dataIndex: 'details',
            //   width: '10%',

        },
        {
            title: 'Location',
            dataIndex: 'location',
            //   width: '10%',

        },
        {
            title: 'Total Cost',
            dataIndex: 'allCost',
            //   width: '10%',

        }
    ]



    const approvedTraining = props.approvedTraining.map((item) => (
        {
            title: item.title,
            organisingBody: item.organisingBody,
            startDate: item.startDate.substr(0, 10),
            endDate: item.endDate.substr(0, 10),
            details: item.details,
            location: item.location,
            allCost: `₦ ${item.allCost ? numberWithCommas(item.allCost) : 0}`
        }
    ))




    return (
        <div >
            <div className="flex p-20">
                <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>Personal Training</Radio>
                    <Radio value={2}>Approved training</Radio>
                </Radio.Group>

            </div>

            {
                value === 1 ?
               <div className="p-20">
                <div className=' primary-color p-l-20 w-100 p-20'>
                    <p>{props.cardTitle || "Self Training"}</p>
                </div>
                <Table
                    bordered
                    dataSource={selfTraining}
                    columns={selfColumns}
                    rowClassName="editable-row"
                />
            </div>

                :
                
            <div className="m-20">
                <div className=' primary-color p-l-20 w-100 p-20'>
                    <p>{props.cardTitle || "Approved Training"}</p>
                </div>
                <Table
                    bordered
                    dataSource={approvedTraining}
                    columns={approvedColumns}
                    rowClassName="editable-row"
                />
            </div> 
            }
            

        </div>
    );
}

export default TrainingDetails;