import Item from 'antd/lib/list/Item';
import React from 'react';
import TextInput from '../inputs/TextInput';

const PersonalTarget = (props) => {
    return (
        <div className='flex flex-direction-column w-100'>
            <div className='m-b-20 primary-color w-100 p-20'>
                <p>{props.cardTitle || "Osagie's Personal Profile"}</p>
            </div>
            <div className='white-color p-20 p-l-40'>
                <h2>
                    Set Targets
                </h2>
                <p>
                    The Chief executive in consultation with the director-general and the directors set out the following targets for my Division/Branch/Section to achieve the following:
                </p>
                <div className='flex space-between'>
                <h4 className='inline-block w-75'>
                    Commitments:
                </h4>
                <h4 className='inline-block w-20'>Weight</h4>
                </div>
                
                {
                    props.personalTarget.map((item) => (
                        <div key = {item.objective} className='flex space-between'>
                            <TextInput readOnly value={item.objective} className='inputs inline-block w-75' />  <TextInput readOnly value={item.weight || 'Office of the Commisioner for Education'} className='inputs inline-block w-20' />
                        </div>
                    ))
                }


            </div>

        </div>
    );
}


export default PersonalTarget;