import { useEffect, useState } from "react";
import { get } from "../../utility/treasuryFetch"
import Greeting from "../common/Greeting"
import Select from 'react-select'
import { Table, Spin } from 'antd';
import { numberWithCommas } from "../../utility/general";


const ApproverReport = (id) => {

    const [data, setData] = useState([]);
    const [types, setTypes] = useState([]);
    const [table, setTables] = useState([]);
    const [tableAll, setTableAll] = useState([]);
    const [allData, setAllData] = useState([])
    const [showAllData, setShowAllData] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);


    const columns = [

        {
            title: 'Approver',
            dataIndex: 'role',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'Number of Vouchers',
            dataIndex: 'count',
            // width: '55%',
            editable: false,
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            // width: '55%',
            editable: false,
        }

    ]


    const getAllData = async () => {
        setTableLoading(true);
        let response1 = await get(`/Treasury/PendingStatsByRole?voucherTypeId=${1}`)
        let response2 = await get(`/Treasury/PendingStatsByRole?voucherTypeId=${2}`)
        let response3 = await get(`/Treasury/PendingStatsByRole?voucherTypeId=${3}`)
        let response5 = await get(`/Treasury/PendingStatsByRole?voucherTypeId=${5}`)
        let response6 = await get(`/Treasury/PendingStatsByRole?voucherTypeId=${6}`)

        if (response1?.status === "success" &&
            response2?.status === "success" &&
            response3?.status === "success" &&
            response5?.status === "success" &&
            response6?.status === "success") {
            setAllData([{
                "role": "DFA",
                "totalAmount": `₦ ${response1?.data[0]?.totalAmount + response2?.data[0]?.totalAmount + response3?.data[0]?.totalAmount + response5?.data[0]?.totalAmount + response6?.data[0]?.totalAmount}`,
                "count": ` ${response1?.data[0]?.count + response2?.data[0]?.count + response3?.data[0]?.count + response5?.data[0]?.count + response6?.data[0]?.count}`
            },
            {
                "role": "Internal Audit",
                "totalAmount": `₦ ${response1?.data[1]?.totalAmount + response2?.data[1]?.totalAmount + response3?.data[1]?.totalAmount + response5?.data[1]?.totalAmount + response6?.data[1]?.totalAmount}`,
                "count": ` ${response1?.data[1]?.count + response2?.data[1]?.count + response3?.data[1]?.count + response5?.data[1]?.count + response6?.data[1]?.count}`
            },
            {
                "role": "FinalAccount",
                "totalAmount": `₦ ${response1?.data[2]?.totalAmount + response2?.data[2]?.totalAmount + response3?.data[2]?.totalAmount + response5?.data[2]?.totalAmount + response6?.data[2]?.totalAmount}`,
                "count": ` ${response1?.data[2]?.count + response2?.data[2]?.count + response3?.data[2]?.count + response5?.data[2]?.count + response6?.data[2]?.count}`
            },
            {
                "role": "ExpenseControl",
                "totalAmount": `₦ ${response1?.data[3]?.totalAmount + response2?.data[3]?.totalAmount + response3?.data[3]?.totalAmount + response5?.data[3]?.totalAmount + response6?.data[3]?.totalAmount}`,
                "count": ` ${response1?.data[3]?.count + response2?.data[3]?.count + response3?.data[3]?.count + response5?.data[3]?.count + response6?.data[3]?.count}`
            },
            {
                "role": "AG",
                "totalAmount": `₦ ${response1?.data[4]?.totalAmount + response2?.data[4]?.totalAmount + response3?.data[4]?.totalAmount + response5?.data[4]?.totalAmount + response6?.data[4]?.totalAmount}`,
                "count":` ${ response1?.data[4]?.count + response2?.data[4]?.count + response3?.data[4]?.count + response5?.data[4]?.count + response6?.data[4]?.count}`
            },
            {
                "role": "MgtAccount",
                "totalAmount": `₦ ${response1?.data[5]?.totalAmount + response2?.data[5]?.totalAmount + response3?.data[5]?.totalAmount + response5?.data[5]?.totalAmount + response6?.data[5]?.totalAmount}`,
                "count":` ${ response1?.data[5]?.count + response2?.data[5]?.count + response3?.data[5]?.count + response5?.data[5]?.count + response6?.data[5]?.count}`
            },
            {
                "role": "TreasuryCashAccount",
                "totalAmount": `₦ ${response1?.data[6]?.totalAmount + response2?.data[6]?.totalAmount + response3?.data[6]?.totalAmount + response5?.data[6]?.totalAmount + response6?.data[6]?.totalAmount}`,
                "count": ` ${response1?.data[6]?.count + response2?.data[6]?.count + response3?.data[6]?.count + response5?.data[6]?.count + response6?.data[6]?.count}`
            },
            {
                "role": "Admin",
                "totalAmount": `₦ ${response1?.data[7]?.totalAmount + response2?.data[7]?.totalAmount + response3?.data[7]?.totalAmount + response5?.data[7]?.totalAmount + response6?.data[7]?.totalAmount}`,
                "count": ` ${response1?.data[7]?.count + response2?.data[7]?.count + response3?.data[7]?.count + response5?.data[7]?.count + response6?.data[7]?.count}`
            },
            {
                "role": "Initiator",
                "totalAmount":`₦ ${response1?.data[8]?.totalAmount + response2?.data[8]?.totalAmount + response3?.data[8]?.totalAmount + response5?.data[8]?.totalAmount + response6?.data[8]?.totalAmount}`,
                "count": ` ${response1?.data[8]?.count + response2?.data[8]?.count + response3?.data[8]?.count + response5?.data[8]?.count + response6?.data[8]?.count}`
            },
            {
                "role": "PS",
                "totalAmount": `₦ ${response1?.data[9]?.totalAmount + response2?.data[9]?.totalAmount + response3?.data[9]?.totalAmount + response5?.data[9]?.totalAmount + response6?.data[9]?.totalAmount}`,
                "count": ` ${response1?.data[9]?.count + response2?.data[9]?.count + response3?.data[9]?.count + response5?.data[9]?.count + response6?.data[9]?.count}`
            },
            {
                "role": "Inspectorate",
                "totalAmount": `₦ ${response1?.data[10]?.totalAmount + response2?.data[10]?.totalAmount + response3?.data[10]?.totalAmount + response5?.data[10]?.totalAmount + response6?.data[10]?.totalAmount}`,
                "count": ` ${response1?.data[10]?.count + response2?.data[10]?.count + response3?.data[10]?.count + response5?.data[10]?.count + response6?.data[10]?.count}`
            },
            {
                "role": "Governor",
                "totalAmount": `₦ ${response1?.data[11]?.totalAmount + response2?.data[11]?.totalAmount + response3?.data[11]?.totalAmount + response5?.data[11]?.totalAmount + response6?.data[11]?.totalAmount}`,
                "count": ` ${response1?.data[11]?.count + response2?.data[11]?.count + response3?.data[11]?.count + response5?.data[11]?.count + response6?.data[11]?.count}`
            },
            {
                "role": "Auditor General",
                "totalAmount": `₦ ${response1?.data[12]?.totalAmount + response2?.data[12]?.totalAmount + response3?.data[12]?.totalAmount + response5?.data[12]?.totalAmount + response6?.data[12]?.totalAmount}`,
                "count": ` ${response1?.data[12]?.count + response2?.data[12]?.count + response3?.data[12]?.count + response5?.data[12]?.count + response6?.data[12]?.count}`
            },
            {
                "role": "Final Account (Auditor General)",
                "totalAmount": `₦ ${response1?.data[13]?.totalAmount + response2?.data[13]?.totalAmount + response3?.data[13]?.totalAmount + response5?.data[13]?.totalAmount + response6?.data[13]?.totalAmount}`,
                "count": ` ${response1?.data[13]?.count + response2?.data[13]?.count + response3?.data[13]?.count + response5?.data[13]?.count + response6?.data[13]?.count}`
            },
            {
                "role": "Paid",
                "totalAmount": `₦ ${response1?.data[14]?.totalAmount + response2?.data[14]?.totalAmount + response3?.data[14]?.totalAmount + response5?.data[14]?.totalAmount + response6?.data[14]?.totalAmount}`,
                "count": ` ${response1?.data[14]?.count + response2?.data[14]?.count + response3?.data[14]?.count + response5?.data[14]?.count + response6?.data[14]?.count}`
            }])

            setTableLoading(false)
        }
    }



    useEffect(() => {
        setTables(
            Array.isArray(data) && data.map((data) => (
                {
                    key: data.id,
                    role: data.role,
                    totalAmount: numberWithCommas(data.totalAmount),
                    count: data.count
                }
            ))
        )
    }, [data])


    useEffect(() => {
        setTableAll(
            Array.isArray(allData) && allData.map((allData) => (
                {
                    key: allData.id,
                    role: allData.role,
                    totalAmount: numberWithCommas(allData.totalAmount),
                    count: allData.count
                }
            ))
        )
    }, [allData])


    const getStatsByVoucherTypes = async (id) => {
        setTableLoading(true)
        let res = await get(`/Treasury/PendingStatsByRole?voucherTypeId=${id}`)
        console.log(res)
        if (res?.status === 'success') {
            setData(res?.data);
            setTableLoading(false)
        }
    }

    const getVoucherTypes = async () => {
        let res = await get("/voucher/type");
        console.log(res)
        if (res?.status === "success") {
            let types = res?.data?.filter((item) => item.id !== 4).map((item) => {
                return {
                    value: item?.id,
                    label: item?.description
                }
            })
            types?.unshift({ label: "All Vouchers", value: 0 })
            setTypes(types)
        }

    }

    const handleTypes = (value) => {
        console.log(value);
        if (value?.value === 0) {
            setShowAllData(true);
            return
        }
        setShowAllData(false);
        getStatsByVoucherTypes(value?.value);

    }

    useEffect(() => {
        getVoucherTypes();
        getAllData();
    }, [])

    return (
        <div className="w-100">
            <div className='m-b-40'>
                <Greeting />
            </div>
            <div className="bg-white p-20">
                <h3>Select Voucher Type:</h3>
                <div className="w-40">
                    <Select className="w-100" options={types} onChange={handleTypes} defaultValue={{ label: "All Vouchers", value: 0 }} />
                </div>

                <div style={{ width: "100%", marginTop: "40px" }}>
                    {!showAllData ?
                        <Table
                            bordered
                            dataSource={table}
                            columns={columns}
                            rowClassName="editable-row"
                            loading={{
                                indicator: <div> <Spin /> </div>, spinning: tableLoading
                            }} /> :
                        <Table
                            bordered
                            dataSource={tableAll}
                            columns={columns}
                            rowClassName="editable-row"
                            loading={{
                                indicator: <div> <Spin /> </div>, spinning: tableLoading
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
export default ApproverReport;