/* eslint-disable no-unused-vars */
import React from "react";
import { IoMdClose } from "react-icons/io";
import Table from "./Table";
import parser from "html-react-parser";
import DocTable from "./DocTable";
import { PDFExport } from "@progress/kendo-react-pdf";
import edologo from "../../assets/images/edo-logo-250.png";
import festaclogo from "../../assets/images/Festac-logo.jpg";
import {numberWithCommas} from "../../utility/general";


const ViewDetailsPopup = ({
  details,
  closeModal,
  expenseItems,
  supportingDoc,
  expenseQuery,
  approvers,
  ...props
}) => {
  const pdfExportComponent = React.useRef(null);

  const downloadPdf = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  return (
    <div className="overlay box-shadow flex-h-center flex-v-center ">
      <div className="flex flex-v-center bg-transparent flex-h-center flex-direction-column m-b-20">
        <div
          className="modal-box bg-transparent box-shadow"
          style={{
            position: "relative",
            width: "1000px",
          }}
        >
           <button className="btn w-20 p-10 m-b-10" onClick={downloadPdf}>
            Print Memo PDF
          </button>
          <div className="content no-padding w-100">
            <IoMdClose
              className="pointer m-l-5"
              style={{
                position: "absolute",
                top: "0",
                left: "100%",
                fontSize: "24px",
                color: "#fff",
                width: "40px",
                height: "40px",
                backgroundColor: "#F74F11",
                padding: "3px",
              }}
              onClick={() => {
                closeModal();
              }}
            />
            <PDFExport ref={pdfExportComponent}>
              <div className="w-" style={{ width: "1000px" }}>
                <div className="w-100 bg-white ">
                  <div
                    className="w-100"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: "16px",
                      padding: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ height: "50px" }}
                      src={edologo}
                      alt=""
                      srcset=""
                    />
                    <p
                      className="bold-text sixteen"
                      style={{ fontStyle: "bold", fontSize: "16px" }}
                    >
                      {details?.mda?.toUpperCase()}
                    </p>
                    <img
                      style={{ width: "50px" }}
                      src={festaclogo}
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div className="flex flex-direction-column">
                    <div
                      className="bold-text"
                      style={{
                        padding: "15px",
                        backgroundColor: "#d5ffd5",
                        fontSize: "20px",
                        fontStyle: "bold",
                      }}
                    >
                      {/* Expense Details */}
                      Subject: {details?.title?.toUpperCase()}
                    </div>
                  </div>
                </div>
                <div className="w-100 bg-white pd-10">
                  <div className="flex flex-direction-column">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "16px",
                      }}
                    >
                      {/* <div className="flex  p-5 pd-b-5">
                        <div className=" no-padding no-margin bold-text sixteen">
                          Title:{" "}
                        </div>
                        <div className="w-80 bold-text sixteen">{details?.title} </div>
                      </div> */}
                      {props?.manageRequest && (
                        <div className="flex p-5 pd-b-5">
                          <div className=" no-padding no-margin nineteen m-r-10">
                            From:{" "}
                          </div>
                          <div className="w-80 bold-text sixteen">
                            {details?.staffFrom?.toUpperCase()}{" "}
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="flex  p-5 pd-b-5  m-t-10">
                        <div className="no-padding nineteen no-margin m-r-10">
                          Details:{" "}
                        </div>
                        <div
                          className="w-80 bold-text sixteen sixteen"
                          style={{
                            border: "1px solid #c5c6d0",
                            borderRadius: "12px",
                            padding: "0 10px 10px 10px",
                          }}
                        >
                          {parser(
                            details?.details?.replaceAll(
                              "<td>",
                              "<td className='border-table'>"
                            )
                          )}{" "}
                        </div>
                      </div>
                      {/* {props?.manageRequest && (
                        <div className="flex p-5 pd-b-5">
                          <div className="m-r-10 nineteen no-padding no-margin ">
                            MDA:{" "}
                          </div>
                          <div className="w-80 bold-text sixteen">{details?.mda} </div>
                        </div>
                      )} */}
                    </div>

                    <div className="flex p-5 pd-b-5 m-t-10 m-r-10">
                      <div className="nineteen no-padding no-margin m-r-10">
                        Reference Number:{" "}
                      </div>
                      <div className=" bold-text sixteen">
                        {details?.referenceNumber}
                      </div>
                    </div>
                    <div className="flex p-5 pd-b-5 m-t-10">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Vendor:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">
                        {details?.vendor ||
                          details?.vendorName ||
                          details?.requisitionVendor}{" "}
                      </div>
                    </div>
                    <div className="flex p-5 pd-b-5">
                      <div className="nineteen no-padding no-margin m-r-10">
                        Invoice Number:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">
                        {details?.invoiceNo}{" "}
                      </div>
                    </div>
                    {/* <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Item Count:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">{details?.itemCount} </div>
                    </div> */}
                    <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Total Amount:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">
                        {details?.currency}{" "}
                        {numberWithCommas(details?.amount) || numberWithCommas(details?.totalAmount)}{" "}
                      </div>
                    </div>
                    {/* <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Currency:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">{details?.currency} </div>
                    </div> */}
                    {/* <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Currency Rate:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">
                        {details?.currencyRate}{" "}
                      </div>
                    </div> */}
                    {/* <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Query Status:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">
                        {details?.queryStatus ? "Yes" : "No"}{" "}
                      </div>
                    </div> */}
                    {/* <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Date Submitted:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">
                        {details?.dateSubmitted}{" "}
                      </div>
                    </div> */}
                    {/* <div className="flex p-5 pd-b-5">
                      <div className="m-r-10 nineteen no-padding no-margin ">
                        Last Updated:{" "}
                      </div>
                      <div className="w-80 bold-text sixteen">{details?.lastUpdated} </div>
                    </div> */}
                  </div>
                </div>
                <div className="w-100 bg-white m-t-10 ">
                  <div className="flex flex-direction-column">
                    <div className="flex flex-direction-column">
                      <div
                        style={{
                          padding: "15px",
                          backgroundColor: "#43425D",
                          color: "#fff",
                        }}
                      >
                        Approvers
                      </div>
                    </div>
                    <Table
                      header={[
                        "Approver Name",
                        "Role",
                        "Status",
                        "Date Approved",
                        "Comment",
                        "Signature",
                      ]}
                      tableBody={approvers}
                      propToUse={[
                        "approverName",
                        "role",
                        "status",
                        "dateApproved",
                        "comment",
                        "signature64",
                      ]}
                    />
                  </div>
                </div>
                <div className="w-100 bg-white m-t-10 ">
                  <div className="flex flex-direction-column">
                    <div className="flex flex-direction-column">
                      <div
                        style={{
                          padding: "15px",
                          backgroundColor: "#43425D",
                          color: "#fff",
                        }}
                      >
                        Expense Items
                      </div>
                    </div>
                    <Table
                      header={[
                        "Item Name",
                        "Budget Item",
                        "Description",
                        "Quantity",
                        "Economic Code",
                        "Amount",
                      ]}
                      tableBody={expenseItems}
                      propToUse={[
                        "itemName",
                        "budgetItem",
                        "description",
                        "quantity",
                        'budgetItemId',
                        "amt",
                      ]}
                    />
                  </div>
                </div>
                <div className="w-100 bg-white m-t-10 ">
                  <div className="flex flex-direction-column">
                    <DocTable
                      headers={["Supporting Document"]}
                      tableBody={supportingDoc}
                      action={false}
                    />
                  </div>
                </div>
                {/* <div className="w-100 bg-white m-t-10 ">
                  <div className="flex flex-direction-column">
                    <div
                      style={{
                        padding: "15px",
                        backgroundColor: "#43425D",
                        color: "#fff",
                      }}
                    >
                      Expense Query Table
                    </div>
                    <Table
                      header={[
                        "Sender",
                        "Message",
                        "Date Sent",
                        // 'Status',
                        "Response Message",
                        "Date Replied",
                      ]}
                      tableBody={expenseQuery}
                      propToUse={[
                        "sender",
                        "message",
                        "dateSent",
                        // 'status',
                        "responseMessage",
                        "dateReplied",
                      ]}
                    />
                  </div>
                </div> */}
              </div>
            </PDFExport>
          </div>
          <div className="flex m-t-10">
            {props.manageRequest &&  props?.btnStatus === "Pending" &&  (
              <div>
                <button
                  className="btn m-r-10"
                  style={{ width: `${props.btnWidth || "105px"}` }}
                  onClick={() => {
                    props?.setStatus("Approve");
                    // props?.setShowAuthModal(true);
                    props?.setAuth(true);
                    props?.setId([]);
                    props?.setShowCommentModal(true);
                    props?.setCurrentItem(details);
                    props?.setSingle(true);
                    props?.setBtnText("Approve");
                  }}
                >
                  {props?.btnText || "Approve"}
                </button>

                <button
                  className="btn m-r-10"
                  style={{ backgroundColor:'red', width: `${props.btnWidth || "105px"}` }}
                  onClick={() => {
                    props?.setStatus("Decline");
                    // props?.setShowAuthModal(true);
                    props?.setAuth(true);
                    props?.setId([]);
                    props?.setShowCommentModal(true);
                    props?.setCurrentItem(details);
                    props?.setSingle(true);
                    props?.setBtnText("Decline");
                  }}
                >
                  {props?.btnText || "Decline"}
                </button>

                <button
                  className="btn m-r-10"
                  style={{ backgroundColor:'purple', width: `${props.btnWidth || "105px"}` }}
                  onClick={() => {
                    props?.setStatus("Inquiry");
                    // props?.setShowAuthModal(true);
                    props?.setAuth(true);
                    props?.setId([]);
                    props?.setShowCommentModal(true);
                    props?.setCurrentItem(details);
                    props?.setSingle(true);
                    props?.setBtnText("Inquiry");
                    props?.Auth && props?.getQueries();
                  }}
                >
                  {props?.btnText || "Send Inquiry"}
                </button>
              </div>
            )}
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsPopup;
