import React, { useEffect, useState } from 'react';


import { TiArrowSortedDown } from 'react-icons/ti';

import UserAvater from '../../assets/images/user-avater.png';


import { formatFileUrl } from '../../utility/general';
import Cookies from 'js-cookie';

const Header = ({ history, details = {}, navList = [] }) => {
  const [userPix, setUserPix] = useState('');
  const [imgHasError, setImgHasError] = useState(false);

  useEffect(() => {
    !imgHasError && setUserPix(formatFileUrl(details.userProfilePicture));
  }, [imgHasError, details]);

  const handleImgError = () => {
    setUserPix('');
    setImgHasError(true);
  };

  return (
    <header>
      <div className="content header-content space-between flex-v-center">
        <div></div>
        <div className="header-right  flex-v-center ">
          <div className="right-item float-right">
            <div
              role="presentation"
              onClick={() => {}}
              className="user-avater-details flex flex-v-center pointer"
            >
              <div className="m-r-5 right-text" style={{ paddingTop: '3px' }}>
                <p className="name">{Cookies.get('fullName') || 'Osagie Osaigbovo'}</p>
                <p className="role">{Cookies.get('role')|| 'Officer 1'}</p>
              </div>
              <div className="flex">
                <img onError={handleImgError} src={userPix || UserAvater} alt="user avater" />
              </div>
              <div className="flex arrow">
                <TiArrowSortedDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
