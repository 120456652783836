import React, { useState, useEffect, useContext } from "react";
import {
  Input,
  Modal,
  Select,
  Form,
  Card,
  Pagination,
  Spin,
} from "antd";
// import {
//   DeleteOutlined,
//   UploadOutlined,
//   PaperClipOutlined,
//   DeleteTwoTone,
// } from "@ant-design/icons";


import { get, put } from "../../utility/fetchBudget";
import axios from "axios";
import Festac from "../../assets/images/Festac-logo.jpg";
import EdsgLogo from "../../assets/images/edo-logo-250.png";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import { decodeToken } from "../../utility/auth";
// import PreviewScreen from './PreviewScreen';
// import EditScreen from './EditScreen';
import {
  CloseOutlined,
} from "@ant-design/icons";
// import { AiOutlineCloseSquare } from "react-icons/ai";
// import { SwapTableContext } from "../Contexts/SwapTableContext";
import EditableTableRec from "../layouts/EditableTableRec";
import EditableTableP from "../layouts/EdotableTableP";
import EditableBudgetItems from "../layouts/EditableBudgetItems";
import EditableTableCapital from "../layouts/EditableTableCapital";
import BudgetItemsDisplay from "../layouts/BudgetItemsDisplay";

// const BASE_API_URL = 'http://localhost:5000/v1';

const collectCamelCaseAndReturnSpacedString = (e) => {
  switch (e) {
    case "budgetItems":
      return "OverHead";
    // break;
    case "personnelCosts":
      return "Personal Expenditure";
    case "recurrentRevenues":
      return "Recurrent Revenue";
    case "capitalProjects":
      return "Capital Expenditure";
    case "capitalReceipts":
      return "Capital Receipts";
    // case "overHeadCostMonitor":
    //   return "Overhead Cost Monitor";
    case "revenueMonitor":
      return "Revenue Monitor";

    default:
      console.log("It's a weekend day or an invalid day.");
  }
};

const renameHeadings = (key) => {
  if (key == "programmeCode") return "Programme Code" || "adminCode";
  else if (key == "administrativeCode" || key == "adminCode")
    return "Administrative Code";
  else if (
    key == "economicCode" ||
    key == "economicCodeMain" ||
    key == "economicMainHeadCode"
  )
    return "Economic Code";
  else if (
    key == "functionalMainHeadCode" ||
    key == "functionalMainCode" ||
    key == "functionalCode"
  )
    return "Functional Code";
  else if (key == "locationCode" || key == "geoCode") return "Location";
  else if (key == "fundMainCode" || key == "geoCode") return "Fund Code";
  else if (
    key == "proposedBudget" ||
    key == "proposedExpense" ||
    key == "proposedRevenue" ||
    key == "proposedAppropriation"
  )
    return "Proposed Budget";
  else if (key == "details" || key == "economicDetails") return "Details";
  else if (key == "costPerMonth") return "Cost per Month";
  else if (key == "costPerAnnum") return "Cost per Annum";
  else if (key == "fundCodeMain" || key == "fundCode") return "Fund Code";
};

const AdminBudgetReqForReports = (prop) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [preview, setPreview] = useState(false);
  const [mdaIdFiltered, setmdaIdFiltered] = useState("");
  const [pendingConfirmationArray, setpendingConfirmationArray] =
    useState(false);
  const [approvedConfirmationArray, setApprovedConfirmationArray] =
    useState(false);
  const [isDirectorAccepted, setIsDirectorAccepted] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [paginationLength, setPaginationLength] = useState(136);
  const [subject, setSubject] = useState("");
  const [response, setResponse] = useState("");
  const [userId, setuserId] = useState([]);
  const [newArray, setNewArray] = useState([]);
  const [pendingAssignedEnvelopeObj, setPendingAssignedEnvelopeObj] = useState(
    {}
  );
  const [PSenvelopeAssignmentObj, setPSenvelopeAssignmentObj] = useState({
    isAssigned: false,
    // isAccepted: false,
  });

  const [mdaId, setMdaId] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [submits, setSubmit] = useState(false);
  const [saves, setSaves] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [switching, setswitching] = useState(true);
  const [approvedByMdaButton, setapprovedByMdaButton] = useState(true);
  const [reducedPendingRequests, setReducedPendingRequests] = useState([]);
  const [shortPending, setShortPending] = useState(false);
  const [mappending, setMapPending] = useState([]);
  const [mapSaved, setMapSaved] = useState([]);
  const [savedRequests, setSavedRequests] = useState([]);
  const [shortSaved, setShortSaved] = useState(true);
  const [reducedSavedRequests, setReducedSavedRequests] = useState([]);
  const [toClearTable, settoClearTable] = useState(false);
  const [propz, setPropz] = useState("");
  const [propzApproved, setPropzApproved] = useState([]);
  const [envelopeData, setEnvelopeData] = useState();
  const [payload, setPayload] = useState();
  const [signature, setsignature] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [PSAssignedEnvelope, setPSAssignedEnvelope] = useState([]);
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [imageEditArray, setImageEditArray] = useState([]);

  // const [payload, setpayload] = useState(0);
  const [acceptedBudgetEnvelope, setAcceptedBudgetEnvelope] = useState();
  const [dummyBudgetTables, setdummyBudgetTables] = useState();
  const [isDummyBudgetTables, setIsDummyBudgetTables] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [editableMdas, setEditableMdas] = useState([]);

  const [listOfMdas, setListOfMdas] = useState([]);
  const [budgetPeriod, setBudgetPeriod] = useState([]);
  const [SelectedbudgetPeriod, setSelectedBudgetPeriod] = useState();
  const [
    SelectedbudgetPeriodControlledValue,
    setSelectedBudgetPeriodControlledValue,
  ] = useState();
  const [SelectedMdaId, setSelectedMdaId] = useState();

  const [budgetPeriodID, setBudgetPeriodID] = useState();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const data = [
    {
      firstname: "jill",
      lastname: "smith",
      age: 22,
      style: { font: { sz: "24", bold: true } },
    },
    { firstname: "david", lastname: "warner", age: 23 },
    { firstname: "nick", lastname: "james", age: 26 },
  ];

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const onChangePage = (page) => {
    setspinner(true);
    console.log(page);
    getApprovedMdasByID(budgetPeriod?.find((e) => e.isCurrent)?.id, page, 30);
    setcurrentPage(page);
  };

  useEffect(() => {
    // Cookies.get("isBudgetPS");
    console.log(Cookies.get("isBudgetPS"));

    console.log(+Cookies.get("mdaId"));

    setMdaId(+Cookies.get("mdaId"));

    console.log(Cookies.get("signature"));
    console.log(Cookies.get("firstName"));
    console.log(Cookies.get("lastName"));
    setsignature(Cookies.get("signature"));

    getBudgetPeriod();
    getEmployeeList();
    // getPendingConfirmationBudget();
    getApprovedConfirmationBudget();
    getMdas();
  }, [refresh]);

  useEffect(() => {
    console.log(mappending);
    shortPending
      ? setMapPending(reducedPendingRequests)
      : setMapPending(pendingRequests);
  }, [shortPending]);

  
  useEffect(() => {
    console.log(mapSaved);
    shortSaved ? setMapSaved(reducedSavedRequests) : setMapSaved(savedRequests);
  }, [shortSaved]);

  const getMdas = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)

    const res = await get(
      `/v1/mda`,
      // auth: true,
      // body: payload,
    );
    if (res) {
      console.log(res);
      setListOfMdas(res);

      // this.setState({
      //     allBudgetCodification: response,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response,
      //     loader: false,
      // });
    }
  };

  const saveAndRefreshApprovedMDAs = async (payload) => {
    console.log(payload);

    if (switching) {
      setPropzApproved([payload]);
      setdummyBudgetTables(payload);
      setIsDummyBudgetTables(true);
      return;
    } else {
      const res = await put(
        `${!switching
          ? "/v1/BudgetPlanning"
          : "/v1/BudgetPlanning/UpdateApprovedBudget"
        }`,
        // auth: true,
        payload,
      );
      // alert('inevitable')
      console.log(payload);

      if (res) {
        setdummyBudgetTables();
        setIsDummyBudgetTables(false);

        notification({
          title: "Success",
          message: data.message || "You have successfully updated this entry",
          type: "success",
        });
        getApprovedMdasByID(
          budgetPeriod?.find((e) => e.isCurrent)?.id,
          currentPage,
          30
        );
      }
    }
  };

  const refeshwithoutPost = () => {
    getApprovedMdasByID(
      budgetPeriod?.find((e) => e.isCurrent)?.id,
      currentPage,
      30
    );
  };

  useEffect(() => {
    refeshwithoutPost();
  }, [switching]);

  const getApprovedMdasByID = async (payload, mdaId, pageNumber, pageSize) => {
    setspinner(true);

    const res = await get(
    
        // switching
        // ?
        `/v1/BudgetPlanning/GetApprovedBudgetPlanningByMdaId?periodId=${payload}&MdaId=${mdaId}`,
      // : `/v1/BudgetPlanning/GetBudgetPlanningByPeriodId?periodId=${payload}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      // auth: true,
      // body: payload,
      // pQuery: { pageNumber: pageNumber, pageSize: pageSize },
    );

    // console.log(res.headers);

    if (res ) {
      setspinner(false);
      setdummyBudgetTables();
      setIsDummyBudgetTables(false);

     

      console.log(res?.length != 0 ? [] : res[0]?.budgetItems);
      console.log(res);
      setMapPending(res?.length == 0 ? [] : res[0]?.budgetItems);
      setPaginationLength(
        res?.length == 0 ? [] : res[0]?.totalRecords
      );
      console.log(res?.length && res[0]?.totalRecords);

      setPropzApproved(res);

      const mergedObject = res?.reduce((result, obj) => {
        for (const key in obj) {
          if (result?.hasOwnProperty(key)) {
            console.log(key);
            // If the property already exists, merge the arrays
            // result[key] = result[key] && result[key]?.concat(obj[key]);
          } else {
            // If the property doesn't exist, create a new array
            // result[key] = obj[key];
            console.log(key);
          }
        }
        return result;
      }, {});

      console.log(mergedObject);

      setNewArray(mergedObject);
    }
  };

  const getBudgetPeriod = async () => {
    // axios
    //   .get("https://erpapi.edostate.gov.ng/newbudgetapi/Settings")

    const res = await get("/Settings");
    if (res) {
      console.log(res);
      setBudgetPeriod(res);
      setBudgetPeriodID(res?.find((e) => e.isCurrent).id);

      // this.setState({
      //     allBudgetCodification: response,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response,
      //     loader: false,
      // });
    }
  };

  const getEmployeeList = async () => {
    const res = await get(
      `/v1/employee?q=f `
    );
    console.log(res);

    if (res ) {
      const { data } = res;
      if (res) {
        console.log(data);
        setEmployee(data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getApprovedConfirmationBudget = async () => {
    const res = await get("/BudgetEnvelope/GetApprovedBudgetEnvelopes"
      // auth: true,
      // body: payload,
    );

    setApprovedConfirmationArray(res);
    // setPayload(res?)
  };

  const showPreview = () => {
    setPreview(!preview);
    // setPropz('preview')
  };

  const handleCancel = () => {
    showPreview(!preview);
    setPropz("");
    // setRefresh(refresh+1)
    // holdMfilesArray([])
    setIsLoading2(false);
    setIsLoading(false);
    refeshwithoutPost();
    // onReset()
  };

  // console.log(mFilesArray)

  return (
    <div className="w-100">
      {true && (
        <div
          className="w-100 flex justify-center"
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            backgroundColor: "#D5iFFD5",
          }}
        >
          {/* <button onClick={createExcelFile}>Create Excel File</button> */}

          <div className="flex gap w-100 m-r-10 m-b-20 m-t-20">
            <div style={{ width: "100%" }} className="m-r-10">
              {/* <div
                style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
                className="p-10  m-b-10 m-r-10"
              >
                <p>MDA Budgets </p>
              </div> */}
              <div
                class="flex justify-between m-r-10 m-b-10"
              // style={{ justifyContent: "space-between" }}
              >
                {/* <button
                  onClick={() => {
                    setswitching(false);
                    // refeshwithoutPost();
                  }}
                  style={{
                    fontSize: "16",
                    backgroundColor: !switching && "#D5FFD5",
                  }}
                >
                  Pending Budget
                </button> */}
                <button
                  className="m-r-10"
                  onClick={() => {
                    setswitching(true);
                    setapprovedByMdaButton(true);
                    !approvedByMdaButton &&
                      setSelectedBudgetPeriodControlledValue(undefined);

                    // refeshwithoutPost();
                  }}
                  style={{
                    fontSize: "16",
                    backgroundColor: approvedByMdaButton && "#D5FFD5",
                  }}
                >
                  Admin Report By MDA
                </button>

                {/* <button
                  onClick={() => {
                    setswitching(true);
                    setapprovedByMdaButton(false);
                    getApprovedMdas(SelectedbudgetPeriodControlledValue,1,30)
                    setSelectedMdaId(undefined)

                    // refeshwithoutPost();
                  }}
                  style={{
                    fontSize: "16",
                    backgroundColor: !approvedByMdaButton && "#D5FFD5",
                  }}
                >
                  View All Approved Budget
                </button> */}
              </div>

              <div
                class="flex justify-between m-r-10 w-100"
                style={{ justifyContent: "space-between" }}
              >
                <div className="m-r-10 " style={{ width: "40%" }}>
                  <Select
                    // onChange={(e) => getApprovedMdas(e, 1, 30)}
                    onChange={(e) => {
                      setSelectedBudgetPeriodControlledValue(e);
                      approvedByMdaButton
                        ? setSelectedBudgetPeriod(e)
                        : getApprovedMdasByID(e, 1, 30);
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    allowClear
                    style={{ width: "100%", border: "" }}
                    placeholder="Please select Budget Period"
                    value={SelectedbudgetPeriodControlledValue}
                  >
                    {Array.isArray(budgetPeriod) &&
                      budgetPeriod
                        ?.filter((e) => e?.isCurrent)
                        ?.map((obj) => (
                          <Option
                            value={obj?.id}
                            key={obj?.id}
                          >{`${obj?.title}`}</Option>
                        ))}
                  </Select>
                </div>

                {approvedByMdaButton && (
                  <div className="" style={{ width: "40%" }}>
                    <Select
                      // mode="multiple"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(e) => {
                        getApprovedMdasByID(SelectedbudgetPeriod, e?.value);
                        setSelectedMdaId(e);
                      }}
                      labelInValue
                      disabled={!SelectedbudgetPeriod}
                      allowClear
                      value={SelectedMdaId}
                      style={{ width: "100%", border: "" }}
                      placeholder="Please search and select MDA you want to edit select"
                    >
                      {Array.isArray(listOfMdas) &&
                        listOfMdas?.map((obj) => (
                          <Option
                            value={obj?.id}
                            key={obj?.id}
                          >{`${obj?.name}`}</Option>
                        ))}
                    </Select>
                  </div>
                )}
              </div>

              {spinner ? (
                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : (
                <div className="m-b-10 flex flex-wrap m-t-10 gap m-r-10">
                  {propzApproved?.map((e, idx) => (
                    <div className="m-t-5 m-r-10">
                      <Card
                        key={idx}
                        onClick={() => {
                          console.log(e);
                          setPreview(true);
                          setmdaIdFiltered(e.mdaId);
                        }}
                        className="no-margin m-b-5 m-t-5 border pointer mFiles-hover"
                        hoverable
                      >
                        <div>
                          <p
                            className="no-margin bold-text"
                            style={{ fontSize: "12px" }}
                          >
                            {e?.mda}
                          </p>
                          <p
                            className="no-margin"
                            style={{ fontSize: "10px", opacity: "0.5" }}
                          >
                            {e?.dateReceived &&
                              moment(e.dateReceived).format("LL")}
                          </p>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
              {!approvedByMdaButton && (
                <Pagination
                  current={currentPage}
                  onChange={onChangePage}
                  total={136}
                  showSizeChanger={false}
                  pageSize={30}
                />
              )}
              <Modal
                width="80%"
                footer={false}
                style={{ backgroundColor: "transparent" }}
                // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                closeIcon={
                  <CloseOutlined
                    style={{
                      fontSize: "25px",
                      backgroundColor: "red",
                      color: "white",
                      padding: "10px",
                      marginLeft: "60px",
                    }}
                  />
                }
                visible={preview}
                onCancel={handleCancel}
              >
                {propzApproved
                  ?.filter((e) => e?.mdaId == mdaIdFiltered)
                  ?.map((budgetTables, idx) => (
                    <div
                      className={idx % 2 == 0 ? "champ" : ""}
                      key={idx}
                      style={{
                        marginTop: "40px",
                        backgroundColor: idx % 2 == 0 ? "ffdab9" : "",
                      }}
                    >
                      {/* <ModaledViewProjects
               propzApproved={propzApproved}
               /> */}
                      <h1 className="bg-white p-10 center-text bold m-b-10 m-t-20">
                        {budgetTables?.mda}
                      </h1>
                      {/* {switching && (
                        <div style={{ marginRight: "10px", width: "100%" }}>
                          <div className="flex flex-between flex-align">
                            <h3 className="p-5 bold bg-white">
                              Budget Summary
                            </h3>{" "}
                            <Popconfirm
                              title="Sure to send for Approval? Once sent, It cannot be reversed?"
                              onConfirm={sendApprovedEditedBudgetForApproval}
                            >
                              <Button
                                style={{
                                  backgroundColor: "#1C811C",
                                  color: "white",
                                }}
                              >
                                Update Budget
                              </Button>
                            </Popconfirm>
                          </div>
                          <BudgetItemsDisplay budgetTables={budgetTables} />
                        </div>
                      )} */}
                      {/* Can be brpught back */}
                      <BudgetItemsDisplay
                        budgetTables={budgetTables}
                        viewOnly={prop.viewOnly}
                      />

                      <div>
                        <div style={{ overflowX: "scroll" }}>
                          <h3 className="p-5 bold bg-white">Overhead</h3>
                          <div style={{ width: "130%" }}>
                            <EditableBudgetItems
                              viewOnly={prop.viewOnly}
                              saveAndRefreshApprovedMDAs={
                                saveAndRefreshApprovedMDAs
                              }
                              propzApproved={budgetTables}
                              refeshwithoutPost={refeshwithoutPost}
                              isAdminEdit={false}
                              switching={switching}
                            />
                          </div>
                        </div>
                        <div style={{ overflowX: "scroll" }}>
                          <h3 className="p-5 bold bg-white">
                            Recurrent Revenue
                          </h3>
                          <div style={{ width: "130%" }}>
                            <EditableTableRec
                              viewOnly={prop.viewOnly}
                              saveAndRefreshApprovedMDAs={
                                saveAndRefreshApprovedMDAs
                              }
                              refeshwithoutPost={refeshwithoutPost}
                              switching={switching}
                              propzApproved={budgetTables}
                              isAdminEdit={false}
                            />
                          </div>
                        </div>
                        <div style={{ overflowX: "scroll" }}>
                          <h3 className="p-5 bold bg-white">Personnel Costs</h3>
                          <div style={{ width: "130%" }}>
                            <EditableTableP
                              viewOnly={prop.viewOnly}
                              saveAndRefreshApprovedMDAs={
                                saveAndRefreshApprovedMDAs
                              }
                              budgetPeriodID={budgetPeriodID}
                              refeshwithoutPost={refeshwithoutPost}
                              switching={switching}
                              propzApproved={budgetTables}
                              isAdminEdit={false}
                            />
                          </div>
                        </div>
                        {/* <div style={{ overflowX: "scroll" }}>
                        <h3 className="p-5 bold bg-white">
                          Overhead Cost-Monitor
                        </h3>
                        <div style={{ width: "150%" }}>
                          <EditableTableOverHead
                            saveAndRefreshApprovedMDAs={
                              saveAndRefreshApprovedMDAs
                            }
                            propzApproved={budgetTables}
                          />
                        </div>
                      </div> */}
                        <div className="m-t-40" style={{ overflowX: "scroll" }}>
                          <h3 className="p-5 bold bold bg-white">
                            Capital Expenditure
                          </h3>
                          <div style={{ width: "100%" }}>
                            <EditableTableCapital
                              viewOnly={prop.viewOnly}
                              saveAndRefreshApprovedMDAs={
                                saveAndRefreshApprovedMDAs
                              }
                              refeshwithoutPost={refeshwithoutPost}
                              switching={switching}
                              isAdminEdit={false}
                              propzApproved={budgetTables}
                            />
                          </div>
                        </div>

                        {/* <div className="m-t-40" style={{ overflowX: "scroll" }}>
                          <h3 className="p-5 bold bold bg-white">
                            Capital Receipts
                          </h3>
                          <div style={{ width: "100%" }}>
                            <EditableTableReceipts
                            viewOnly={prop.viewOnly}

                              saveAndRefreshApprovedMDAs={
                                saveAndRefreshApprovedMDAs
                              }
                              refeshwithoutPost={refeshwithoutPost}
                              switching={switching}
                              propzApproved={budgetTables}
                              isAdminEdit={false}
                            />
                          </div> 
                        </div>*/}
                      </div>

                      {/* Can be brpught back */}
                    </div>
                  ))}
                {/* <EditableBudgetItems propzApproved={budgetTables} /> */}
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminBudgetReqForReports;
