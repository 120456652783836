import React, { useEffect, useState } from 'react';
import Greeting from '../common/Greeting'
import Page6Cards from '../layouts/Page6Cards';
import SearchInput from '../inputs/SearchInput';
import { Table, Button, Tooltip, Pagination } from 'antd';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineFileDone } from 'react-icons/ai';
import { BiBarChart } from 'react-icons/bi';
import { RiMailSendLine } from 'react-icons/ri'
import { Modal } from 'antd';
import EmployeeDetails from './EmployeeDetails';
import CardDetails from '../layouts/CardDetails';
import PersonalTarget from '../layouts/PersonalTarget';
import Message from '../layouts/Message';
import Performance from '../layouts/Performance';
import { Get } from '../../utility/fetch'
import { get, put } from '../../utility/treasuryFetch'
import { URLAPI } from '../../utility/ApiMethods';
import { RiArrowDownSFill } from 'react-icons/ri'

import ReactSelect from 'react-select';
import axios from 'axios';
import { onYesNoAlert } from '../../resources/alert';
import parser from 'html-react-parser';
import { GrStatusGoodSmall } from 'react-icons/gr';
import notification from '../../utility/notification';
import searchByColumns from '../../utility/searchByColumns';

import { Spin } from 'antd';
import { getDeptName } from '../../utility/general';




const Finance = ({ history }) => {



  const [mda, setMda] = useState(null);
  const [mdaStats, setMdaStats] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [dropDownOptions, setDropDownOptions] = useState(false)
  const [totalMdas, setTotalMdas] = useState(0);
  const [totalStaff, setTotalStaff] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState({})
  const [performance, setPerformance] = useState([]);
  const [staffAppraisal, setStaffAppraisal] = useState([]);
  const [agencyStaffStrength, setAgencystaffStrength] = useState(0)
  const [filterButtonText, setFilterButtonText] = useState("Select MDA")
  const [isModalVisible0, setIsModalVisible0] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [table, setTables] = useState([])
  const [reset, setReset] = useState(false)
  const [financeData, setFinanceData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [bankBalance, setBankBalance] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [liability, setLiability] = useState(null);
  const [paginationLength, setPaginationLength] = useState(0)
  const [cardCounts, setCardCounts] = useState({
    approved: 0,
    pending: 0,
    declined: 0,
    saved: 0,
    total: 0,
  });




  function capitalizeFirstLetter(string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const getLiabilities = async () => {
    let res;
    try {

      res = await get(`/Treasury/Liability`);
      console.log(res)
      // const totalAmountRaised = res?.data;
      if (res.status === 'success') {
        setLiability(res?.data);
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error?.message
      });
    }

  };


  const getBankBalance = async () => {
    try {
      const res = await get(`/Ledger/activeBalance`);
      console.log(res)
      // const currentBalance = res?.data?.pop();

      if (res.status === 'success') {
        console.log(res)
        setBankBalance(res?.data?.currentBalance)
      }
    } catch (error) {
      console.log(error);
      notification({
        type: 'error',
        message: error?.message
      });
    }
  };


  const [title, setTitle] = useState('');

  var data = [];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Finance Dashboard';
    setTitle('Finance Dashboard');
    Get(URLAPI.Dashboard.Stats, setTotalDetails);
    Get(URLAPI.Dashboard.MdaList, populateDropdown);
    fetchFinanceStats();
    getBankBalance();

    getLiabilities();
  }, []);






  const numberWithCommas = (x) => {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  const fetchFinanceStats = async () => {
    setTableLoading(true)
    const res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=ApprovalCompleted`);
    if (res.status === 'success') {
      setPaginationLength(res?.data?.totalItemCount)
      // const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // // Filter the array to get the elements that do not pass the filter condition
      // const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // // Concatenate the filtered elements at the beginning of the array
      // const newArr = filteredElements.concat(nonFilteredElements);

      setFinanceData(res?.data?.items)
      // setCardCounts({
      //   approved: res.data?.items?.filter((r) => r.voucher.statusDetails == 'ApprovalCompleted'),
      //   pending: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Pending'),
      //   declined: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Rejected'),
      //   capital: res.data?.items?.filter((r) => r?.voucher?.voucherType == 'Capital Voucher' && r?.voucher?.statusDetails == 'Pending'),
      //   recurrent: res.data?.items?.filter((r) => (r.voucher.voucherType === 'Salary Voucher' || r.voucher.voucherType === 'Recurrent Voucher' || r.voucher.voucherType === 'Overhead Voucher') && r?.voucher?.statusDetails == 'Pending'),
      //   saved: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Unasigned'),
      //   govAll: res.data?.items?.filter((r) => (r.voucher.voucherType === 'Salary Voucher' || r.voucher.voucherType === 'Recurrent Voucher' || r.voucher.voucherType === 'Overhead Voucher' || r.voucher.voucherType === 'Capital Voucher') && r.voucher.statusDetails === 'Pending'),
      //   govPending: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Pending' && (r.voucher.voucherType === 'Salary Voucher' || r.voucher.voucherType === 'Recurrent Voucher' || r.voucher.voucherType === 'Overhead Voucher' || r.voucher.voucherType === 'Capital Voucher'))

      // })
      getBankBalance();
      setTableLoading(false)
    }
    setTableLoading(false)
  }

  const setTotalDetails = (data) => {
    if (Array.isArray(data.result)) {
      setTotalMdas(data?.result[0]?.agencyCount);
      setTotalStaff(data?.result[0]?.civilServants);
    }
  }


  console.log(financeData)

  const populateDropdown = (data) => {
    if (Array.isArray(data.result)) {
      setDropDownOptions(
        data.result.map((item) => (
          {
            key: item.id,
            value: item.id,
            label: item.name
          }
        ))
      )
    }
  }



  const handleSelected = (selected) => {
    setMda(selected.label)
    setFilterButtonText(selected.label)
    Get(URLAPI.Dashboard.MdaStaff, updateStaffList, selected.value)
    Get(URLAPI.Dashboard.MdaStats, updateStats, selected.value)
  }


  const updateStaffList = (data) => {
    toggleSearchButton();

    console.log(data)
    setEmployees(data.result)
    Array.isArray(data.result) ? setAgencystaffStrength(data?.result.length) : setAgencystaffStrength(0);
  }


  const updateStats = (data) => {
    Array.isArray(data.result) && data.result.length !== 0 ? setMdaStats(data.result[0]) : setMdaStats([])
  }


  const toggleSearchButton = () => {
    setShowSearch(!showSearch);
    setTables([]);
    setMdaStats([])
  }

  const showModal = (num) => {

    if (num === 1) {
      setIsModalVisible1(true);
    }
    else if (num === 2)
      setIsModalVisible2(true);
    else if (num === 3)
      setIsModalVisible3(true);
    else {
      setIsModalVisible0(true);
    }

  };

  const handleEmployee = (record) => {
    setEmployeeRecords(record)
    showModal(0)
    // setReset(false)
  }

  // const handlePay = async (record) => {
  //   const payload = {
  //     voucher: {
  //       id: record.all.voucher.id,
  //       comment: "approved",
  //       chequeUrl: [
  //         "string"
  //       ],
  //       isMgtAcct: false
  //     },
  //     schedule: {
  //       id: record.all.schedule.id,
  //       comment: "Approved",
  //       isMgtAcct: false
  //     }
  //   }
  //   const res = await put(`Treasury/Form/Approve`, payload);
  //   if (res) {
  //     fetchFinanceStats()
  //   }

  // }

  const handlePersonal = (record) => {
    console.log(record.id)
    setEmployeeRecords(record)
    Get(URLAPI.Dashboard.Personal, handlePersonalData, record.id)
  }

  const handlePersonalData = (data) => {
    console.log(data)
    setStaffAppraisal(data.result);
    showModal(1);
  }

  const handlePerformance = (record) => {
    setEmployeeRecords(record)
    Get(URLAPI.Dashboard.DetailedAppraisal, handlePerformanceData, record.id)
  }

   useEffect(() => {
      fetchFinanceStats();
  }, [pageSize, pageNumber])

  const paginationChangeHandler = (current, pageSize) => {
    // setLimit(pageSize);
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePerformanceData = (data) => {
    setPerformance(data.result);
    showModal(2)
  }

 

  const handleCancel = () => {
    setReset(true);
    setIsModalVisible0(false);
    setIsModalVisible1(false);
    setIsModalVisible2(false);
    setIsModalVisible3(false);
  };
  const columns = [
    {
      title: 'MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE APPROVED',
      dataIndex: 'dateApproved',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF CONTRACTOR',
      dataIndex: 'nameOfContractor',
      // width: '55%',
      editable: false,
    },
    {
      title: 'PURPOSE OF PAYMENT',
      dataIndex: 'purposeOfPayment',
      width: '30%',

    },
    {
      title: 'AMOUNT APPROVED',
      dataIndex: 'amountApproved',
      width: '10%',

    },
    {
      title: 'AMOUNT PAID',
      dataIndex: 'amountPaid',
      width: '10%',

    },
    {
      title: 'OUTSTANDING',
      dataIndex: 'outstanding',
      // width: '25%',

    },
    {
      title: 'STATUS',
      render: (text, record) => (
        <div className='flex flex-v-center'>
          <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record.all?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record.all?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
          <div className='float-right'>{record.all?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record.all?.voucher?.journeyStateId)}` : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : record.all?.voucher?.statusDetails === 'Rejected' ? 'Declined' : record.all?.voucher?.statusDetails}</div>
        </div>)
    },
  ];



  useEffect(() => {
    setTables(
      financeData.map((entry) => (
        {
          mda: entry?.voucher?.mda,
          dateApproved: new Date(entry?.voucher?.dateModified).toUTCString(),
          nameOfContractor: entry?.voucher?.drTo,
          purposeOfPayment: parser(entry?.voucher?.description),
          amountApproved: `₦${numberWithCommas(entry?.voucher?.amountApproved)}`,
          amountPaid: `₦${numberWithCommas(Number(entry?.voucher?.amount))}`,
          outstanding: `₦${numberWithCommas(Number(entry?.voucher?.amountApproved || 0) - Number(entry?.voucher?.amount))}`,
          remarks: entry?.voucher?.agComment,
          all: entry
        }
      ))
    )
  }, [financeData])




  return (
    <div className='w-100'>
      <div className='m-b-40'>
        <Greeting />
      </div>

      <div className="flex space-between w-100 m-b-50">
        <Page6Cards
          cardTitle='Total Amount Approved'
          count={liability ? `₦${numberWithCommas(liability?.totalAmountRaised?.toFixed(2))}` : <Spin />}
        />
        <Page6Cards
          cardTitle='Total Amount Outstanding'
          count={liability ? `₦${numberWithCommas(liability?.totalPending?.toFixed(2))}` : <Spin />}
        />
        <Page6Cards
          cardTitle="Total Amount Paid"
          count={liability ? `₦${numberWithCommas(liability?.totalPaid?.toFixed(2))}` : <Spin />}
        />
        {/* <Page6Cards cardTitle="MDA's Directors" count={mdaStats.directorCount}/>
        <Page6Cards cardTitle="Total Monthly Salary" count={`₦ ${mdaStats.monthlySalary ? numberWithCommas(mdaStats.monthlySalary) : 0}`}/> */}
      </div>

      {
        <div>
          <h1>BANK BALANCE: {bankBalance ? `₦${numberWithCommas(String(bankBalance))}` : <Spin />} </h1>
          {/* <button className='btn btn-large' onClick={handleBankBalanceModal}>Enter Current Bank Balance</button> */}
        </div>
      }

      <div className='flex flex-direction-column w-100'>
        <div className='flex space-between ' style={{ width: '70%', marginLeft: 'auto', }}>

          <div className='flex w-100 flex-h-end' >
            {/* {
              showSearch ? <ReactSelect autoFocus className='w-50 m-r-10'  options={dropDownOptions} onChange={handleSelected} /> :
              //   <button className='btn btn-large w-50 space-between flex flex-v-center flex-h-center m-b-20 m-r-10 w-40 ' onClick={toggleSearchButton}>
              //     <span>{filterButtonText} </span> <RiArrowDownSFill style={{ color: 'white', fontSize: "30px", paddingBottom: '5px' }} />
              //   </button>

                  <Button block className='flex w-50 m-r-10' style={{backgroundColor:'#1C811C', color:'white',padding:'21px', justifyContent:'space-between',alignItems:'center'}}  onClick={toggleSearchButton}>
                  <p style={{marginBottom:'0'}}>{filterButtonText} </p> <RiArrowDownSFill style={{ color: 'white', fontSize: "30px", paddingBottom: '5px', marginLeft:'5px' }} />
                  </Button>
            } */}
            {/* <SearchInput onChange={(e) => setFinanceData(searchByColumns(filterData, e.target.value))} className='m-b-20' /> */}
          </div>

        </div>

        <div className='flex space-between primary-color flex-v-center p-20' >

          <h3>{'Finance'} </h3>

          {/* <Button>
            Target vs Actuals
          </Button> */}
        </div>

        <div style={{ width: "100%", }}>


          <Table
            bordered
            dataSource={table}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ x: 400 }}
            loading={{
              indicator: <div> <Spin /> </div>, spinning: tableLoading
            }}
          />
        </div>
        {paginationLength > 0 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              showSizeChanger
              pageSize={pageSize}
              onShowSizeChange={paginationChangeHandler}
              current={pageNumber}
              total={paginationLength}
              // showTotal={true}
              onChange={paginationChangeHandler}
              // pageSizeOptions={pageSizeOptions}
              responsive
            />
          </div>
        )}
      </div>


      <Modal footer={null} visible={isModalVisible0} onCancel={handleCancel} width={1200}>
        <CardDetails department={mda} cardTitle={`${capitalizeFirstLetter(employeeRecords.firstname)} ${capitalizeFirstLetter(employeeRecords.lastname)}'s Personal Profile`} />
        <EmployeeDetails reset={reset} employeeRecord={employeeRecords} />
      </Modal>




      <Modal footer={null} visible={isModalVisible1} onCancel={handleCancel} width={1200}>
        <PersonalTarget personalTarget={staffAppraisal} cardTitle={`${employeeRecords.firstname} ${employeeRecords.lastname}'s Personal Profile`} />
      </Modal>

      <Modal footer={null} visible={isModalVisible2} onCancel={handleCancel} width={1200}>
        <CardDetails department={mda} cardTitle={"Performance"} />
        <Performance performance={performance} />
      </Modal>

      <Modal footer={null} visible={isModalVisible3} onCancel={handleCancel} width={1200}>
        <Message />
      </Modal>

    </div>
  );
};

export default Finance;
