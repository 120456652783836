import React, { useEffect, useState } from 'react';
import Greeting from '../common/Greeting'
import { Checkbox, Spin, Table } from 'antd';
import { get } from '../../utility/treasuryFetch';




const Settings = ({ history }) => {


    const [title, setTitle] = useState('');
    const [table, setTables] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [pending, setPending] = useState(false)
    const [tableLoading, setTableLoading] = useState(false);


    const getPendingBySelectedRoles = async (queryString) =>{
        setTableLoading(true)
        let res = await get(`/Treasury/Paginated/PendingBySelectedRole?PageNumber=1&PageSize=10${queryString}`)
        console.log(res)
        if(res?.status === 'success'){
            getSelectedRoles();
        }
        setTableLoading(false)
    }


    const getRoles = async() =>{
        setTableLoading(true)
        let res = await get("/AccessRoles")
        if(res?.status === "success"){
            console.log(res)
            setTables(res?.data?.result?.item1)
        }
        setTableLoading(false)
    }

    const getSelectedRoles = async() =>{
        let res = await get("/Treasury/SelectedRole")
        if(res?.status === "success"){
            console.log(res)
            setSelectedRoles(res?.data)
        }
    }

    const generateQueryString = (role, action) =>{
        let qString = "";
        console.log(selectedRoles)
        if(action === "add"){
            let newRoleSet = [...selectedRoles, role] ;
            setSelectedRoles(newRoleSet);
            console.log(newRoleSet)
            newRoleSet?.map((item)=> qString = qString + `&RoleIds=${item?.id}`)
            console.log(qString)
            getPendingBySelectedRoles(qString)
        }
        else{
            let newRoleSet = selectedRoles?.filter((item)=> role?.id !== item?.id);
            setSelectedRoles(newRoleSet);
            console.log(newRoleSet);
            newRoleSet?.map((item)=> qString = qString + `&RoleIds=${item?.id}`)
            console.log(qString)
            getPendingBySelectedRoles(qString)

        }
    }

    const handleSelectStatus = (record) =>{
        console.log(record)
        console.log(selectedRoles)
        let queryString;
        let status = selectedRoles?.find((role) => role.id === record?.id)

        if(status){
            queryString = generateQueryString(record, "sub");
        }
        else{
            queryString = generateQueryString(record, "add")
        }


    }


    const toggleStatus = (id) =>{
        let status = selectedRoles.find(roles => roles.id === id)
        if(status){
            return true;
        }
        return false;
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'EDSG - Governor Settings';
        setTitle('Governor Settings');
        getRoles()
        getSelectedRoles()
    }, []);

    const columns = [

        {
            title: 'Status',
            dataIndex: 'description',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Checkbox checked = {toggleStatus(record?.id)}  onChange={() => handleSelectStatus(record)}/>
                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        setTables(
            []
        )
    }, [])


    useEffect(() => {

    }, [])


    return (
        <div className='w-100'>

            <div className='m-b-40'>
                <Greeting />
            </div>

            <h2>Governor's Settings</h2>

            <div style={{ width: "100%", }}>

                <Table
                    bordered
                    dataSource={table}
                    columns={columns}
                    paginated={true}
                    loading={{
                        indicator: <div> <Spin /> </div>, spinning: tableLoading
                      }}
                    rowClassName="editable-row"
                />

            </div>
        </div>
    );
};

export default Settings;
