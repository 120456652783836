import { IoMdClose } from "react-icons/io";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useState } from "react";
import { useEffect } from "react";
import { month } from "../../utility/general"
import { get } from "../../utility/treasuryFetch";
import Select from "react-select";
import { Spin } from 'antd';

const ReqTableHeaders = [
    'Initiator MDA',
    "Voucher Type",
    "Purpose",
    'Date of Creation',
    'Name of Vendor/Organization',
    'Amount',
    'PV Number'
]


const filterOptions = [
    {
        label: "Capital Voucher",
        value: "Capital Voucher"
    },
    {
        label: "Recurrent Voucher",
        value: "Recurrent Voucher"
    },
    {
        label: "Overhead Voucher",
        value: "Overhead Voucher"
    },

]

const PrintModal = ({ closeModal, paymentVoucher, count, year, bank, office }) => {
    const [cashBookList, setCashBookList] = useState([]);
    const [voucherType, setVoucherType] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(false);

    const numberWithCommas = (x) => {
        if (String(x).includes(',')) {
            return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else {
            return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    useEffect(() => {
        const temp = paymentVoucher?.data?.filter((record) => {
            return record?.voucher?.account?.bank === bank && record?.voucher?.yearOfPayment === Number(year) && record?.voucher?.monthOfPayment === month
        })
        setCashBookList(temp);
    }, [])


    let getData = async (pageNumber, pageSize, value) => {
        let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${value}`)
        if (res.status === "success") {
            return res?.data?.items
        }
        return;
    }

    const handleChange = async (value) => {
        setLoading(true)
        setVoucherType(value?.label)
        let requests = [];
        let pageNumber = 1;
        let pageSize = 10;
        let tracker = count;

        while (tracker > 0) {
            requests.push(await getData(pageNumber, pageSize, value?.value));
            tracker = tracker - pageSize;
            pageNumber++;
            setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
        }

        Promise.all(requests)
            .then((res) => {
              
                setData(res.flat().filter(item => item.voucher.statusDetails === 'Pending'))
                setLoading(false)
            })


        // let res = await get(`/GetTreasuryFormFiltered?PageNumber=1&PageSize=100&query=${value}`)
        // setData(res?.data?.items)
        // console.log(res)
    }

    return (

        <div className="overlay ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '100%', margin: '0 auto', backgroundColor: "white", height: "auto" }} >
                <div className=" no-padding ">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large w-100 p-20"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <div className="w-100 ">
                            <p className="center-text">
                                OFFICE OF THE GOVERNOR
                            </p>

                            <h1 className="center-text m-b-40">
                                {` PENDING ${voucherType?.toUpperCase()}S AS AT ${month[new Date().getMonth()]?.toUpperCase()} ${new Date().getDate()}`}
                            </h1>

                            <div className="w-100 m-b-20">
                                <Select options={filterOptions} onChange={(e) => handleChange(e)} className="w-100" />
                            </div>


                            <h4 className="flex flex-h-end bolder-text">
                                A/CS GEN.18 (REVISED)
                            </h4>
                            {/* <p className="center-text">
                                {bank}
                            </p> */}


                            <table id="table-to-xls" className="w-100">
                                <thead className="">
                                    <tr className="tr-bordered">
                                        {ReqTableHeaders.map((th, index) => (
                                            <th key={index} className="th th-bordered">
                                                <p>{th}</p>
                                            </th>
                                        ))}
                                    </tr>

                                </thead>
                                {
                                    loading ? <div className="w-100 p-20 flex flex-h-center"><Spin className="w-100" /></div> :
                                        <tbody>
                                            {data?.map((item, index) => {
                                                return (
                                                    <tr role="presentation">

                                                        <td className="td p-r td-bordered">
                                                            {item?.voucher?.mda}

                                                        </td>
                                                        <td className="td p-r td-bordered">
                                                            {item?.voucher?.voucherType}
                                                        </td>
                                                        <td className="td p-r td-bordered">
                                                            {item?.voucher?.purpose}
                                                        </td>
                                                        <td className="td p-r td-bordered">
                                                            {new Date(item?.voucher?.dateCreated).toUTCString()}
                                                        </td>
                                                        <td className="td p-r td-bordered">
                                                            {item?.voucher?.drTo}
                                                        </td>
                                                        <td className="td p-r td-bordered">
                                                            {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                                                                (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                                                            )).toFixed(2))}`}
                                                        </td>
                                                        <td className="td p-r td-bordered">
                                                            {item?.voucher?.pvNo}
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            }
                                        </tbody>

                                }
                            </table>


                        </div>

                        {uploadProgress > 0 && (
                            <div className='m-t-10'>
                                {/* <p>Upload Progress: {uploadProgress}%</p> */}
                                <div style={{ width: '100%', border: '1px solid #ccc' }}>
                                    <div
                                        style={{
                                            width: `${uploadProgress}%`,
                                            height: '12px',
                                            backgroundColor: '#4caf50',
                                            color: '#fff',
                                            textAlign: 'center',
                                            lineHeight: '16px',
                                        }}
                                    >
                                        {uploadProgress}%
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    {/* <button className="btn btn-large w-100 m-t-20"> To Excel</button> */}
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-large w-100 m-t-20"
                        table="table-to-xls"
                        filename={`PENDING ${voucherType?.toUpperCase()} WITH HIS EXCELLENCY AS AT ${month[new Date().getMonth()]?.toUpperCase()} ${new Date().getDate()}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />
                    {/* <button className="btn btn-large w-100 m-t-20">Download as XLS</button>/ */}
                </div>
            </div>
        </div >

    )
}

export default PrintModal;