import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import ExcelExportButton from "./ExcelWorkSheet";

function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const data = {
  capitalBudget: 0,
  amountSpentCapital: 0,
  personnelBudget: 0,
  amountSpentPersonnel: 0,
  overHeadBudget: 0,
  amountSpentOverHead: 0,
  percentageCapital: null,
  percentagePersonnel: null,
  percentageOverHead: null,
  mda: "EDO STATE FIRE SERVICE",
};

const columns = [
  {
    title: "MDA",
    dataIndex: "mda",
    key: "mda",
    fixed: "left",
    sorter: true,
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },

  {
    title: "Capital Budget",
    dataIndex: "capitalBudget",
    key: "capitalBudget",
    isCapital: true,
    isOverhead: false,
    isPersonnel: false,
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
  {
    title: "Capital Budget Spent",
    dataIndex: "amountSpentCapital",
    key: "amountSpentCapital",
    isCapital: true,
    isOverhead: false,
    isPersonnel: false,
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
  {
    title: " % Capital Spent",
    dataIndex: "percentageCapital",
    key: "percentageCapital",
    isCapital: true,
    isOverhead: false,
    isPersonnel: false,
    render: (text, record) => <p>{numberWithCommas(text?.toFixed(2))} %</p>,
  },
  {
    title: "Personnel Budget",
    dataIndex: "personnelBudget",
    key: "personnelBudget",
    isCapital: false,
    isOverhead: false,
    isPersonnel: true,
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
  {
    title: "Personnel Budget Spent",
    dataIndex: "amountSpentPersonnel",
    key: "amountSpentPersonnel",
    isCapital: false,
    isOverhead: false,
    isPersonnel: true,
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
  {
    title: " % Personnel Spent",
    dataIndex: "percentagePersonnel",
    key: "percentagePersonnel",
    isCapital: false,
    isOverhead: false,
    isPersonnel: true,
    render: (text, record) => <p> {numberWithCommas(text?.toFixed(2))} %</p>,
  },

  {
    title: "Overhead Budget",
    dataIndex: "overHeadBudget",
    key: "overHeadBudget",
    isCapital: false,
    isOverhead: true,
    isPersonnel: false,
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
  {
    title: "OverHead Budget Spent",
    dataIndex: "amountSpentOverHead",
    key: "amountSpentOverHead",
    isCapital: false,
    isOverhead: true,
    isPersonnel: false,
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },

  {
    title: "% OverHead Spent",
    dataIndex: "percentageOverHead",
    key: "percentageOverHead",
    isCapital: false,
    isOverhead: true,
    isPersonnel: false,
    render: (text, record) => <p> {numberWithCommas(text?.toFixed(2))} %;</p>,
  },
  {
    title: "Amt Spent Jan",
    dataIndex: "amountSpentJan",
    key: "amountSpentJan",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Feb",
    dataIndex: "amountSpentFeb",
    key: "amountSpentFeb",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Mar",
    dataIndex: "amountSpentMar",
    key: "amountSpentMar",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Apr",
    dataIndex: "amountSpentApr",
    key: "amountSpentApr",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent May",
    dataIndex: "amountSpentMay",
    key: "amountSpentMay",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Jun",
    dataIndex: "amountSpentJun",
    key: "amountSpentJun",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Jul",
    dataIndex: "amountSpentJul",
    key: "amountSpentJul",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },

  {
    title: "Amt Spent Aug",
    dataIndex: "amountSpentAug",
    key: "amountSpentAug",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Sept",
    dataIndex: "amountSpentSept",
    key: "amountSpentSept",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Oct",
    dataIndex: "amountSpentOct",
    key: "amountSpentOct",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Nov",
    dataIndex: "amountSpentNov",
    key: "amountSpentNov",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Dec",
    dataIndex: "amountSpentDec",
    key: "amountSpentDec",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
];

const columns3 = [
  { title: "Mda", dataIndex: "mda", key: "mda", fixed: "left", sorter: true },
  {
    title: "budget Type",
    dataIndex: "budgetType",
    key: "budgetType",
    fixed: "left",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },

  {
    title: "administrative Code",
    dataIndex: "administrativeCode",
    key: "administrativeCode",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Economic Code",
    dataIndex: "economicCode",
    key: "economicCode",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Programme Code",
    dataIndex: "programmeCode",
    key: "programmeCode",
    isCapital: true,
    isOverhead: false,
    isPersonnel: false,
  },
  {
    title: "Item Description",
    dataIndex: "budgetItem",
    key: "budgetItem",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Economic Details",
    dataIndex: "economicDetails",
    key: "economicDetails",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Budgetted Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,

    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
  {
    title: "Amt Spent Jan",
    dataIndex: "amountSpentJan",
    key: "amountSpentJan",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Feb",
    dataIndex: "amountSpentFeb",
    key: "amountSpentFeb",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Mar",
    dataIndex: "amountSpentMar",
    key: "amountSpentMar",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Apr",
    dataIndex: "amountSpentApr",
    key: "amountSpentApr",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent May",
    dataIndex: "amountSpentMay",
    key: "amountSpentMay",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Jun",
    dataIndex: "amountSpentJun",
    key: "amountSpentJun",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Jul",
    dataIndex: "amountSpentJul",
    key: "amountSpentJul",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },

  {
    title: "Amt Spent Aug",
    dataIndex: "amountSpentAug",
    key: "amountSpentAug",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Sept",
    dataIndex: "amountSpentSept",
    key: "amountSpentSept",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Oct",
    dataIndex: "amountSpentOct",
    key: "amountSpentOct",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Nov",
    dataIndex: "amountSpentNov",
    key: "amountSpentNov",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
  {
    title: "Amt Spent Dec",
    dataIndex: "amountSpentDec",
    key: "amountSpentDec",
    isCapital: true,
    isOverhead: true,
    isPersonnel: true,
  },
];

const columns2 = [
  { title: "Mda", dataIndex: "mda", key: "mda", fixed: "left", sorter: true },
  {
    title: "budget Type",
    dataIndex: "budgetType",
    key: "budgetType",
    fixed: "left",
    sorter: true,
  },

  {
    title: "administrative Code",
    dataIndex: "administrativeCode",
    key: "administrativeCode",
  },
  { title: "Economic Code", dataIndex: "economicCode", key: "economicCode" },
  { title: "Programme Code", dataIndex: "programmeCode", key: "programmeCode" },
  { title: "Item Description", dataIndex: "budgetItem", key: "budgetItem" },
  {
    title: "Economic Details",
    dataIndex: "economicDetails",
    key: "economicDetails",
  },
  {
    title: "Amount Spent",
    dataIndex: "totalAmount",
    key: "totalAmount",
    render: (text, record) => (
      <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>
    ),
  },
];

// const Bool = data.find((e)=>e.overHeadBudget)

const ReportTableAll = ({
  data,
  dataDetailMda,
  budgetButtonType,
  coloredBg,
}) => {
  const [newCol, setNewCol] = useState(columns);
  useEffect(() => {
    manipulatedColumn();
  }, [budgetButtonType, data]);

  const manipulatedColumn = () => {
    if (budgetButtonType == "overhead") {
      setNewCol(columns.filter((e) => e.isOverhead));
      console.log(newCol);
    } else if (budgetButtonType == "capital") {
      setNewCol(columns.filter((e) => e.isCapital));
      console.log(newCol);
    } else {
      setNewCol(columns.filter((e) => e.isPersonnel));
      console.log(newCol);
    }
  };

  // console.log(newCol);
  // console.log(newCol);
  console.log(data || dataDetailMda);
  const headings =
    budgetButtonType === "capital"
      ? [
          "MDA",
          "Capital Budget",
          "Amount Spent (Capital)",
          "Percentage of Capital Spent",
          "Amount Spent (Jan)",
          "Amount Spent (Feb)",
          "Amount Spent (Mar)",
          "Amount Spent (Apr)",
          "Amount Spent (May)",
          "Amount Spent (Jun)",
          "Amount Spent (Jul)",
          "Amount Spent (Aug)",
          "Amount Spent (Sep)",
          "Amount Spent (Oct)",
          "Amount Spent (Nov)",
          "Amount Spent (Dec)",
        ]
      : budgetButtonType === "personnel"
      ? [
          "MDA",
          "Personnel Budget",
          "Amount Spent (Personnel)",
          "Percentage of Personnel Spent",
          "Amount Spent (Jan)",
          "Amount Spent (Feb)",
          "Amount Spent (Mar)",
          "Amount Spent (Apr)",
          "Amount Spent (May)",
          "Amount Spent (Jun)",
          "Amount Spent (Jul)",
          "Amount Spent (Aug)",
          "Amount Spent (Sep)",
          "Amount Spent (Oct)",
          "Amount Spent (Nov)",
          "Amount Spent (Dec)",
        ]
      : budgetButtonType === "overhead"
      ? [
          "MDA",
          "Overhead Budget",
          "Amount Spent (Overhead)",
          "Percentage of Overhead Spent",
          "Amount Spent (Jan)",
          "Amount Spent (Feb)",
          "Amount Spent (Mar)",
          "Amount Spent (Apr)",
          "Amount Spent (May)",
          "Amount Spent (Jun)",
          "Amount Spent (Jul)",
          "Amount Spent (Aug)",
          "Amount Spent (Sep)",
          "Amount Spent (Oct)",
          "Amount Spent (Nov)",
          "Amount Spent (Dec)",
        ]
      : [];

  return (
    <div class="w-100 ">
      <div class="w-100 flex " style={{ justifyContent: "flex-end" }}>
        <div style={{ justifySelf: "flex-end" }} className="border">
          <ExcelExportButton
            data={data || dataDetailMda}
            headings={headings}
            fileName="exported_data"
            sheetName="Sheet1"
          />
        </div>
      </div>
      <Table
        dataSource={data?.length > 0 ? data : dataDetailMda}
        columns={
          coloredBg == "fifth" ? columns2 : data?.length > 0 ? newCol : columns3
        }
        pagination={false}
        scroll={{ x: 1900 }}
      />
    </div>
  );
};

export default ReportTableAll;
