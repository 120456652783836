import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, InputNumber } from "antd";
// import "antd/dist/antd.css";
import Cookies from "js-cookie";
import notification from "../../utility/notification";
import { post} from "../../utility/fetchBudget";

const AdditionTable = (prop) => {
  const [dataSource, setDataSource] = useState([]);
  const [formData, setFormData] = useState({
    administrativeCode: "",
    economicDetails: "",
    economicCode: "",
    previousApprovedBudget: 0,
    proposedBudget: 0,
    currentExpenditures: 0,
    previousExpenditures: 0,
    functionalCode: "",
    locationCode: "",
  });

  useEffect(() => {
    console.log(prop.dataFromUp);
  }, [prop.dataFromUp]);

  let mm = {
    budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
    mdaId: +prop?.dataFromUp?.mdaId,
    budgetItems: [
      {
        dateCreated: new Date(),
        enteredBy: Cookies.get("userId"),
        dateUpdated: new Date(),
        updatedBy: Cookies.get("userId"),
        isDelete: false,
        dateDeleted: new Date(),
        budgetPlanId: "",
        budgetDeptId: "",
        budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
        project: "",
        title: "",
        adminSectorCode: 0,
        administrativeCode: "",
        programmeCode: "",
        economicCode: "",
        economicDetailsCod: "",
        economicDetails: "",
        fundCode: "",
        fundDetailsCode: "",
        functionalCode: "",
        functionalDetailsCode: "",
        geoCode: "",
        status: "3",
        projectLocation: "",
        proposedBudget: 0,
        previousApprovedBudget: 0,
        currentExpenditures: 0,
        previousExpenditures: 0,
        performance: "",
        actuals: "",
        mdaId: +prop.dataFromUp?.mdaId,
        approvalStatus: 0,
        stage: 0,
      },
    ],
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddToTable = () => {
    // Validate form data before adding to the table
    if (
      !formData.economicDetails ||
      !formData.economicCode ||
      // !formData.previousApprovedBudget
      // ||
      // !formData.proposedBudget ||
      // !formData.currentExpenditures ||
      !formData.administrativeCode
      // ||
      // !formData.previousExpenditures
    ) {
      alert("Please fill in all fields.");
      return;
    }

    let tu = [
      {
       
        dateCreated: "2024-01-20T08:58:52.052Z",
        enteredBy: "string",
        dateUpdated: "2024-01-20T08:58:52.052Z",
        updatedBy: "string",
        isDelete: true,
        dateDeleted: "2024-01-20T08:58:52.052Z",
        budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        budgetDeptId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        project: "string",
        title: "string",
        adminSectorCode: 0,
        administrativeCode: "string",
        programmeCode: "string",
        economicCode: "string",
        economicDetailsCode: "string",
        economicDetails: "string",
        fundCode: "string",
        fundDetailsCode: "string",
        functionalCode: "string",
        functionalDetailsCode: "string",
        geoCode: "string",
        status: "string",
        projectLocation: "string",
        proposedBudget: 0,
        previousApprovedBudget: 0,
        currentExpenditures: 0,
        previousExpenditures: 0,
        performance: "string",
        actuals: "string",
        mdaId: 0,
        approvalStatus: 0,
        stage: 0,
      },
    ];
    let kk = {
      dateCreated:new Date(),
      enteredBy:Cookies.get("userId"),
      dateUpdated: new Date(),
      updatedBy:Cookies.get("userId"),
      isDelete:false,
      dateDeleted:new Date(),
      budgetPlanId:"3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetDeptId:"3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetPeriodId:"9f5ff7b5-a0cd-4768-9481-75d2ed785540",
      project:"",
      title:"",
      adminSectorCode:0,
      administrativeCode:"",
      programmeCode:"",
      economicCode:"",
      economicDetailsCod:"",
      economicDetails:"",
      fundCode:"",
      fundDetailsCode:"",
      functionalCode:"",
      functionalDetailsCode: "",
      geoCode:"",
      status:"3",
      projectLocation:"",
      proposedBudget:0,
      previousApprovedBudget:0,
      currentExpenditures:0,
      previousExpenditures:0,
      performance:"",
      actuals:"",
      mdaId:+prop?.dataFromUp?.mdaId,
      approvalStatus:0,
      stage:0,
    };

    let pp = { ...kk, ...formData };

    console.log(pp);

    // const yy = [].map((e)=>(
    //     {
    //         ...e
    //     }
    // ))

    // Add form data to the table
    setDataSource((prevDataSource) =>
      [...prevDataSource, pp].map((e) => ({
        ...e,
        economicCode: e?.economicCode,
        economicDetails: e?.economicDetails,
        geoCode: e?.locationCode,
        proposedBudget: +e?.proposedBudget,
        previousApprovedBudget: +e?.previousApprovedBudget,
        currentExpenditures: +e?.currentExpenditures,
        previousExpenditures: +e?.previousExpenditures,
      }))
    );

    // Clear the form data for the next entry
    setFormData({
      ...formData,
      economicCode: "",
      economicDetails: "",
      proposedBudget: 0,
      previousApprovedBudget: 0,
      previousExpenditures: 0,
      currentExpenditures: 0,
    });
  };

  const columns = [
    {
      title: "Administrative Code",
      dataIndex: "administrativeCode",
      key: "administrativeCode",
    },
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "economicCode",
    },
    {
      title: "Details",
      dataIndex: "economicDetails",
      key: "economicDetails",
    },
    {
      title: "Proposed Estimates 2024",
      dataIndex: "proposedBudget",
      key: "proposedBudget",
    },
    {
      title: "Approved Estimates 2023",
      dataIndex: "previousApprovedBudget",
      key: "previousApprovedBudget",
    },
    {
      title: "Actual Expenditure At June 30th 2023",
      dataIndex: "previousExpenditures",
      key: "previousExpenditures",
    },
    {
      title: "Actual Expenditure 2022",
      dataIndex: "currentExpenditures",
      key: "currentExpenditures",
    },
    {
      title: "Functional Code",
      dataIndex: "functionalCode",
      key: "functionalCode",
    },
    {
      title: "Location Code",
      dataIndex: "locationCode",
      key: "locationCode",
    },
  ];
  const toPostAcceptEnvelopeAssignment = async () => {
    // setIsLoading(true);
  };

  const handlePost = async () => {
    console.log(dataSource);

    let payload = {
      budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
      mdaId: +prop?.dataFromUp?.mdaId,
      budgetItems: dataSource,
    };
    const res = await post(
      "/v1/BudgetPlanning/AddOverHeadItems",
      // auth: true,
      payload,
    );

    if (res && res.status == 200) {
      //   setIsLoading(false);
      notification({
        title: "Success",
        message: "Successfully Accepted",
        type: "success",
      });
      prop?.refeshwithoutPost();
      // getBudgetPeriod();
    }
  };

  return (
    <div>
      <Space direction="vertical">
        <div>
          <label className="bold">Administrative Code</label>

          <Input
            placeholder="Administrative Code"
            name="administrativeCode"
            value={formData.administrativeCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="bold">Economic Code</label>

          <Input
            placeholder="Economic Code"
            name="economicCode"
            value={formData.economicCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="bold">Details</label>

          <Input
            placeholder="Details"
            name="economicDetails"
            value={formData.economicDetails}
            onChange={handleChange}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Proposed Estimates</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Proposed Estimates"
            name="proposedBudget"
            value={+formData.proposedBudget}
            onChange={handleChange}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Approved Estimates</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Approved Estimates"
            name="previousApprovedBudget"
            value={+formData.previousApprovedBudget}
            onChange={handleChange}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Actual Expenditure At June 30th 2023</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Actual Expenditure At June 30th 2023"
            name="previousExpenditures"
            value={+formData.previousExpenditures}
            onChange={handleChange}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Actual Expenditure 2022</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Actual Expenditure 2022"
            name="currentExpenditures"
            value={+formData.currentExpenditures}
            onChange={handleChange}
          />
        </div>
        {/* <Input
          placeholder="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <Input
          placeholder="Age"
          name="age"
          value={formData.age}
          onChange={handleChange}
        /> */}

        <Button type="primary" onClick={handleAddToTable}>
          Add to Table
        </Button>
      </Space>

      <Table dataSource={dataSource} columns={columns} />

      <Button onClick={handlePost}>Update Entries For {prop?.dataFromUp?.mda}</Button>
    </div>
  );
};

export default AdditionTable;
