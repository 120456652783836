import React, { useEffect, useState } from 'react';
// import { get } from '../../utility/fetch';

// import { exportTableData } from '../../utility/exportToExcel';
// import useFilteredData from '../../hooks/useFilteredData';
// import useMdaData from '../../utility/useMda';
// import SelectInput from '../inputs/SelectInput';
// import { formatTableHeaderToCamelCase } from '../../utility/general';
// import Greeting from '../layouts/Greeting';
import PageCards from '../layouts/PageCards';
// import Pagination from 'antd';
// import parser from 'html-react-parser';
import moment from 'moment';
import xls from "../../assets/svg/xls.svg"
import { GrStatusGoodSmall } from 'react-icons/gr';
import { Checkbox, Pagination, Table } from 'antd';
import DocumentModal from '../layouts/DocumentModal';
// import Cookies from 'js-cookie';
import SearchInput from '../inputs/SearchInput';
import { onYesNoAlert } from '../../resources/alert';
import notification from '../../utility/notification';
import { get, post, put } from '../../utility/treasuryFetch';
import searchByColumns from '../../utility/searchByColumns';
import { Spin } from 'antd';
import PrintModal from '../layouts/PrintModal';
import { getDeptName, queryMessage, timeAgo } from '../../utility/general';
import Select from 'react-select';
import ReviewForm from '../inputs/ReviewForm';



let moduleUsageMemo;
const FinaneReview = ({ history }) => {
  const [title, setTitle] = useState('');
  const [moduleUsage, setModuleUsage] = useState([]);
  const [managementRequestData, setManagementRequest] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalData, setModalData] = useState({});
  const [documentType, setDocumentType] = useState("")
  const [showPictureModal, setShowPictureModal] = React.useState(false);
  const [managementRequestDataFixed, setManagementFixedRequest] = useState([]);
  const [activeMemo, setActiveMemo] = useState('pending');
  const [bankBalance, setBankBalance] = useState(0)
  const [filteredRequest, setFilteredRequest] = useState([]);
  const [table, setTables] = useState(null)
  const [printData, setPrintData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [searchParam, setSearchParam] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [voucherList, setVoucherList] = useState([]);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [paginationLength, setPaginationLength] = useState(0)
  const [details, setDetails] = useState([])
  const [cardCounts, setCardCounts] = useState({
    approved: 0,
    pending: 0,
    declined: 0,
    saved: 0,
    total: 0,
  });
  const [liability, setLiability] = useState(null);
  const [otherLiability, setOtherLiability] = useState({
    capital: null,
    recurrent: null,
    salary: null,
    overhead: null
  });

  const [queryOptions] = useState([
    {
      value: 1,
      label: "All"
    },
    {
      value: 6,
      label: "On Schedule"
    },
    {
      value: 2,
      label: "Caution"
    },
    {
      value: 3,
      label: "Query"
    },
    {
      value: 4,
      label: "Queried"
    },
    {
      value: 5,
      label: "Overdue"
    }
  ]);


  const getUnder24Hrs = async () => {
    setTableLoading(true);
    let res = await get(`/Treasury/Paginated/isUnder24Hrs?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console?.log(res)
    if (res.status === 'success') {
      setPaginationLength(res?.data?.totalItemCount)
      const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // Filter the array to get the elements that do not pass the filter condition
      const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      setPrintData(filteredElements)

      // Concatenate the filtered elements at the beginning of the array
      const newArr = filteredElements.concat(nonFilteredElements);

      setPendingRequests(newArr)
      setFilteredRequest(newArr);
      setTableLoading(false)
    }
  }

  const getExceeds24Hrs = async () => {
    setTableLoading(true);
    let res = await get(`/Treasury/Paginated/HasExceed24Hrs?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console?.log(res)
    if (res.status === 'success') {
      setPaginationLength(res?.data?.totalItemCount)
      const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // Filter the array to get the elements that do not pass the filter condition
      const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      setPrintData(filteredElements)

      // Concatenate the filtered elements at the beginning of the array
      const newArr = filteredElements.concat(nonFilteredElements);

      setPendingRequests(newArr)
      setFilteredRequest(newArr);

      setTableLoading(false)
    }
  }

  const getExceeds48Hrs = async () => {
    setTableLoading(true);
    let res = await get(`/Treasury/Paginated/HasExceed48Hrs?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console?.log(res)
    if (res.status === 'success') {
      setPaginationLength(res?.data?.totalItemCount)
      const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // Filter the array to get the elements that do not pass the filter condition
      const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      setPrintData(filteredElements)

      // Concatenate the filtered elements at the beginning of the array
      const newArr = filteredElements.concat(nonFilteredElements);

      setPendingRequests(newArr)
      setFilteredRequest(newArr);

      setTableLoading(false)
    }
  }

  const getExceeds48HrsGovernor = async () => {
    setTableLoading(true);
    let res = await get(`/Treasury/Paginated/HasExceed48HrsGovernor?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console?.log(res)
    if (res.status === 'success') {
      setPaginationLength(res?.data?.totalItemCount)
      const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // Filter the array to get the elements that do not pass the filter condition
      const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      setPrintData(filteredElements)

      // Concatenate the filtered elements at the beginning of the array
      const newArr = filteredElements.concat(nonFilteredElements);

      setPendingRequests(newArr)
      setFilteredRequest(newArr);

      setTableLoading(false)
    }
  }

  const getQueried = async () => {
    setTableLoading(true);
    let res = await get(`/Treasury/Paginated/Queried?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console?.log(res)
    if (res.status === 'success') {
      setPaginationLength(res?.data?.totalItemCount)
      const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      // Filter the array to get the elements that do not pass the filter condition
      const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

      setPrintData(filteredElements)

      // Concatenate the filtered elements at the beginning of the array
      const newArr = filteredElements.concat(nonFilteredElements);

      setPendingRequests(newArr)
      setFilteredRequest(newArr);

      setTableLoading(false)
    }
  }

  const handleChangeQuery = (query) => {
    console.log(query)
    if (query?.value === 2) {
      getExceeds24Hrs()
    }
    else if (query?.value === 6) {
      getUnder24Hrs();
    }
    else if (query?.value === 3) {
      getExceeds48Hrs()

    }
    else if (query?.value === 4) {
      getQueried();
    }
    else if (query?.value === 5) {
      getExceeds48HrsGovernor();
    }
    else {
      getPendingRequests()
    }
  }





  const handleFilter = async (status) => {
    setTableLoading(true)
    setFilterStatus(status);
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${status}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount);
      setPendingRequests(res.data.items);
      setFilteredRequest(res.data.items);
      setTableLoading(false)
    }
    else{
      setTableLoading(false)
      notification({
        type: "error",
        message: res?.message
      })
    }
    
  }

  const issueQuery = async (record) => {
    const payload = {

      voucherId: record?.all?.voucher?.id,
      messageContent: "query"

    }
    let res = await post("/treasury/query", payload);
    if (res?.status === "success") {
      notification({
        type: "success",
        message: "Query Sent"
      })
      getPendingRequests();
    }
    console.log(res);
  }

  const handleSelectVoucher = (value) => {
    console.log(value)

    let existingId = voucherList?.find((item) => {
      return value?.voucher?.id === item?.voucherId
    })

    console.log(existingId)

    if (existingId) {
      setVoucherList(voucherList?.filter(item => item.voucherId !== existingId?.voucherId))
    }
    else {
      setVoucherList((prev) => (
        [...prev, { voucherId: value?.voucher?.id, scheduleId: value?.schedule?.id }]
      ))
    }

  }


  const getTreasuryStats = async () => {
    let res = await get(`/Treasury/Stats`);
    if (res?.status === "success") {
      setCardCounts({
        approved: res?.data?.paid,
        pending: res?.data?.pending,
        declined: res?.data?.declined,
        saved: res?.data?.saved,
        total: res?.data?.total,
      })
    }
    console.log(res?.data)
  }


  const handlePayMultiple = async () => {
    setTableLoading(true)
    const payload = voucherList?.map((record) => (
      {
        voucher: {
          id: record.voucherId,
          comment: "approved",
          chequeUrl: [
            "string"
          ],
          approverRoleId: 0,
          accountId: 0
        },
        schedule: {
          id: record.scheduleId,
          comment: "Approved",
          approverRoleId: 0
        }
      }
    ))

    const res = await put(`/Treasury/Form/ApproveMultiple`, payload);
    if (res) {
      getPendingRequests();
      getTreasuryStats();
      getBankBalance()
      getLiabilities()
      getLiabilities(1)
      getLiabilities(2)
      getLiabilities(5)
      getLiabilities(6)
      setVoucherList([]);
      setTableLoading(false)
    }
    else{
      setTableLoading(false)
    }

  }

  const handlePay = async (record) => {
    const payload = {
      voucher: {
        id: record.all.voucher.id,
        comment: record?.comment || "approve",
        chequeUrl: [
          "string"
        ],
        isMgtAcct: false,
        isISPO: record?.isISPO,
        noOfSplit: Number(record?.noOfSplit)
      },
      schedule: {
        id: record.all.schedule.id,
        comment: "Approved",
        isMgtAcct: false
      }
    }

    const res = await put(`/Treasury/Form/Approve`, payload);
    if (res) {
      getPendingRequests();
      getTreasuryStats();
      getBankBalance()
      getLiabilities()
      getLiabilities(1)
      getLiabilities(2)
      getLiabilities(5)
      getLiabilities(6)
    }

  }

  const declinePay = async (record) => {
    const payload = voucherList?.map((record) => (
      {
        voucher: {
          id: record.voucherId,
          comment: "Revalidate",
          chequeUrl: [
            "string"
          ],
          approverRoleId: 0,
          accountId: 0
        },
        schedule: {
          id: record.scheduleId,
          comment: "Revalidate",
          approverRoleId: 0
        }
      }
    ))
    // const payload = {
    //   voucher: {
    //     id: record.all.voucher.id,
    //     comment: "Revalidate",
    //     chequeUrl: [
    //       "string"
    //     ],
    //     approverRoleId: 0,
    //     accountId: 0
    //   },
    //   schedule: {
    //     id: record.all.schedule.id,
    //     comment: "Revalidate",
    //     approverRoleId: 0,
    //   }
    // }
    const res = await put(`/Treasury/Form/RejectMultiple`, payload);
    if (res) {
      getPendingRequests();
      getTreasuryStats();
      getBankBalance()
      getLiabilities()
      getLiabilities(1)
      getLiabilities(2)
      getLiabilities(5)
      getLiabilities(6)
    }

  }


  const declinePayMultiple = async (record) => {
    const payload = {
      voucher: {
        id: record.all.voucherId,
        comment: "Revalidate",
        chequeUrl: [
          "string"
        ],
        isMgtAcct: false
      },
      schedule: {
        id: record.all.scheduleId,
        comment: "Revalidate",
        isMgtAcct: false
      }
    }
    const res = await put(`/Treasury/Form/Reject`, payload);
    if (res) {
      getPendingRequests();
      getTreasuryStats();
      getBankBalance()
      getLiabilities()
      getLiabilities(1)
      getLiabilities(2)
      getLiabilities(5)
      getLiabilities(6)
    }

  }


  const confirmApprove = (record, comment) => {
    onYesNoAlert(handlePay, { ...record, ...comment?.voucher }, "Are You Sure ?");
  }


  // const approve = async (data, comment) => {
  //   // setLoading(true);
  //   const { voucher, schedule } = comment;

  //   const payload = {
  //     voucher: {
  //       id: data?.voucher?.id,
  //       ...voucher
  //     },
  //     schedule: {
  //       id: data?.schedule?.id,
  //       ...schedule
  //     }
  //   }

  //   try {
  //     const res = await put(
  //       '/Treasury/Form/Approve',
  //       payload,
  //       true
  //     );
  //     if (res.status === 'success') {
  //       getPendingRequests();
  //       notification({
  //         type: 'success',
  //         message: "Transaction Aproved Successfully"
  //       });
  //       // setLoading(false);
  //     }
  //     else {
  //       console.log(res)
  //       // setLoading(false);
  //       notification({
  //         type: 'error',
  //         message: res?.message
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // setLoading(false);
  //     notification({
  //       type: 'error',
  //       message: error?.message
  //     });
  //     getPendingRequests();
  //   }
  //   // setLoading(false);
  // };





  const togglePrintModal = () => {
    setShowPrintModal((prev) => !prev)
  }

  const getBankBalance = async () => {
    try {
      const res = await get(`/Ledger/ActiveBalance`);

      // const currentBalance = res?.data?.pop();

      if (res?.status === 'success') {
        console.log(res)
        setBankBalance(res?.data?.currentBalance)
      }
    } catch (error) {
      console.log(error);
      notification({
        type: 'error',
        message: error?.message
      });
    }

  };

  const getLiabilities = async (id) => {
    let res;
    try {
      if (!id) {
        res = await get(`/Treasury/Liability`);
        console.log(res)
        const liabilities = res?.data;
        if (res.status === 'success') {
          setLiability(liabilities)
        }
      }
      else {
        res = await get(`/Treasury/Liability`, { voucherypeId: id });
        console.log(res)

        if (res.status === 'success') {
          if (id === 1) {
            setOtherLiability((prev) => ({
              ...prev,
              capital: res?.data?.totalPending
            }))
          }
          else if (id === 2) {
            setOtherLiability((prev) => ({
              ...prev,
              salary: res?.data?.totalPending
            }))
          }
          else if (id === 5) {
            setOtherLiability((prev) => ({
              ...prev,
              recurrent: res?.data?.totalPending
            }))
          }
          else if (id === 6) {
            setOtherLiability((prev) => ({
              ...prev,
              overhead: res?.data?.totalPending
            }))
          }
        }
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error?.message
      });
    }

  };

  const handleApprove = (data) => {
    setShowReviewForm(true);
    setDetails(data);
  }

  const getPendingRequests = async () => {
    setTableLoading(true)
    try {
      // let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
      let res = await get(`/Treasury/Paginated/PendingBySelectedRole?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
      console.log(res)
      if (res.status === 'success') {
        setPaginationLength(res?.data?.totalItemCount)
        const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

        // Filter the array to get the elements that do not pass the filter condition
        const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

        setPrintData(filteredElements)

        // Concatenate the filtered elements at the beginning of the array
        const newArr = filteredElements.concat(nonFilteredElements);

        setPendingRequests(newArr)
        setFilteredRequest(newArr);

        getBankBalance();
        setTableLoading(false)
      }
    } catch (error) {
      setTableLoading(false)
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'SELECT',
      // dataIndex: 'createdByName',
      //   width: '55%',
      render: (text, record) => {
        return <div><Checkbox disabled={!(record?.all?.voucher?.journeyStateId === 12 && record?.all?.voucher?.isApproved === false)} onChange={() => handleSelectVoucher(record?.all)}></Checkbox></div>
      }
    },
    {
      title: 'TAT',
      // dataIndex: 'createdByName',
      width: '30%',
      render: (text, record) => {
        return <div className='w-100'>
          <div style={{ width: "16px", height: "16px" }} className={`m-r-10 float-left ${record.all?.voucher?.over24Hrs === false ? 'bg-bg-green' : (record.all?.voucher?.over24Hrs === true && record.all?.voucher?.over48Hrs === false) ? 'bg-bg-amber' : (record.all?.voucher?.over24Hrs === true && record.all?.voucher?.over48Hrs === true) ? 'bg-bg-red' : 'bg-gray'}`}></div>
          <div className='no-wrap'>{timeAgo(new Date(record?.all?.voucher?.dateModified))}</div>
        </div>
      }
    },
    {
      title: 'QUERY',
      // dataIndex: 'createdByName',
      //   width: '55%',
      render: (text, record) => {
        return <div>{record?.all?.voucher?.isQueried ? "Queried" : (record.all?.voucher?.over24Hrs === false ? 'On Schedule' : (record.all?.voucher?.over24Hrs === true && record.all?.voucher?.over48Hrs === false) ? 'Caution' : (record.all?.voucher?.over24Hrs === true && record.all?.voucher?.over48Hrs === true) ? (record?.all?.voucher?.journeyStateId !== 12 ? <button className='btn p-r-20 m-t-20 w-100' onClick={() => onYesNoAlert(issueQuery, record, "Are You Sure ?")} > Query </button> : "Overdue") : 'bg-gray')} </div>
      }
    },
    {
      title: 'PV INITIATOR',
      dataIndex: 'createdByName',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'INITIATOR MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'VOUCHER TYPE',
      dataIndex: 'voucherType',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE OF CREATION',
      dataIndex: 'dateCreated',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF VENDOR/ORGANIZATION',
      dataIndex: 'drTo',
      // width: '55%',
      editable: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
    },
    {
      title: 'PV NUMBER',
      dataIndex: 'pvNo',
      width: '10%',

    },
    {
      title: 'PAYMENT VOUCHER',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("voucher", record?.all) }}>View Payment Voucher</a>)

    },
    {
      title: 'SCHEDULE FORM',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("schedule", record.all) }}>View Schedule Form</a>)

    },
    {
      title: 'UPLOADED DOCUMENT',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("memo", record.all) }}>View Approved Memo</a>
      )
    },
    {
      title: 'PAYMENT CHEQUE',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("paymentCheque", record.all) }}>View Payment Cheque</a>
      )
    },
    {
      title: 'STATUS',
      render: (text, record) => (
        <div >

          <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record.all?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record.all?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
          <div className='float-right'>{record.all?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record.all?.voucher?.journeyStateId)}` : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : record.all?.voucher?.statusDetails === 'Rejected' ? 'Declined' : record.all?.voucher?.statusDetails}</div>
        </div>)
    },
    {
      title: 'APPROVALS AND COMMENTS',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("comment", record.all) }}>View Approvals and Comments</a>
      )
    },
    {
      title: 'ACTION',
      render: (text, record) => {
        return <div>
          {
            record?.all?.voucher?.journeyStateId === 12 && record?.all?.voucher?.isApproved === false ?
              <div>
                <div className='flex'>
                  <button className='btn p-r-20 m-r-20' onClick={() => handleApprove(record)} > Pay </button>
                  {/*() => handleApprove(record)*/}
                  <button className='btn-red p-r-20' onClick={() => onYesNoAlert(declinePay, record, "Are You Sure ?")} > Decline </button>
                </div>
              </div>
              :
              (record?.all?.voucher?.isApproved === true) ? <p>Approved</p>
                :
                ""
          }
        </div>
      }

    },
  ]


  const querySearch = async () => {
    setTableLoading(true)
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount)
      setPendingRequests(res.data.items)
      setFilteredRequest(res.data.items)
      setTableLoading(false)
    }
    console.log(res);
    setTableLoading(false)
  }


  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    // querySearch();
  }


  useEffect(() => {
    getPendingRequests();
    getTreasuryStats();
    getBankBalance()
    getLiabilities()
    getLiabilities(1)
    getLiabilities(2)
    getLiabilities(5)
    getLiabilities(6)
  }, []);

  const closeModal = () => {
    setShowPictureModal(false);
  };

  // const fetchRequestData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const { status, data } = await get(
  //       '/GetTreasuryRequestFiltered',

  //       true
  //     );
  //     if (status === 'success') {
  //       setManagementRequest(data);
  //       setManagementFixedRequest(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setIsLoading(true);
  // };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Governor's Finance Review";
    setTitle("Governor's Finance Review");
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  // const onInputChange = async (value) => {
  //   try {
  //     if (value === 'All') return setModuleUsage(moduleUsageMemo);
  //     const mda = mdas.filter((m) => {
  //       return m.name.toLowerCase() === value.toLowerCase();
  //     })[0];
  //     const { status, data } = await get(`/ModuleUsage/${mda.id}`, {}, true);
  //     setModuleUsage(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const toggleReviewModal = () => {
    setShowReviewForm(!showReviewForm);
  }



  const numberWithCommas = (x) => {
    if (String(x).includes(',')) {
      return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    else {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  function sumAmounts(obj) {
    let sum = parseFloat(obj?.amount) || 0;

    if (obj.subVouchers && obj.subVouchers.length > 0) {
      // Recursively calculate the sum for each subvoucher
      obj.subVouchers.forEach(subVoucher => {
        sum += sumAmounts(subVoucher);
      });
    }

    return sum;
  }

  function calculateTotalLiabilitySum(dataArray) {
    return dataArray.reduce((acc, obj) => acc + obj?.voucher?.amountApproved, 0);
  }

  function calculateTotalSum(dataArray) {
    return dataArray.reduce((acc, obj) => acc + sumAmounts(obj?.voucher), 0);
  }

  useEffect(() => {
    setTables(
      filteredRequest.map((entry) => (
        {
          createdByName: entry?.voucher?.createdByName,
          dateCreated: new Date(entry?.voucher?.dateCreated).toUTCString(),
          mda: entry?.voucher?.mda,
          voucherType: entry?.voucher?.voucherType,
          drTo: entry?.voucher?.drTo,
          pvNo: entry?.voucher?.pvNo,
          amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.amount) + entry?.voucher?.subVouchers?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
          )).toFixed(2))}`,
          all: entry
        }
      ))
    )

  }, [filteredRequest])

  const showDocumentModal = (type, data) => {

    setModalData(data);
    setDocumentType(type);
    setShowPictureModal(true);
  }

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setPageNumber(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (searchParam === "") {
      getPendingRequests();
    }
    else if (filterStatus !== "") {
      handleFilter(filterStatus);
    }
    else {
      querySearch();
    }
  }, [pageSize, pageNumber, searchParam])

  console.log(pendingRequests)
  Array.isArray(pendingRequests) && console.log(pendingRequests?.filter((r) => { return r?.voucher?.statusDetails == "ApprovalCompleted" }))
  Array.isArray(pendingRequests) && console.log(pendingRequests?.filter((r) => { return r?.voucher?.statusDetails == "Pending" }))

  const filterLogs = (activeMemo) => {
    setActiveMemo(activeMemo);
    if (activeMemo === 0) {
      setManagementFixedRequest(managementRequestData);
    } else {
      const result = managementRequestData.filter(
        (m) => m.treasuryRequestStatus === activeMemo
      );
      setManagementFixedRequest(result);
    }
  };

  return (
    <div className="w-100">
      {/* <Greeting /> */}
      <div className="flex space-between w-100 m-b-20">
        <PageCards
          cardTitle="Total PV Raised"
          count={cardCounts.total ? cardCounts?.total : <Spin />}
          // onClick={() => filterLogs(0)}
          onClick={() => handleFilter("")}
          isActive={activeMemo === 0}
        />
        <PageCards
          cardTitle="Total PV Pending"
          count={
            cardCounts.pending !== null ? cardCounts.pending : <Spin />
          }

          onClick={() => handleFilter("Pending")}
          isActive={activeMemo === 2}
        />
        <PageCards
          cardTitle="Total PV Paid"
          count={
            cardCounts.approved !== null ? cardCounts?.approved : <Spin />
          }
          // onClick={() => filterLogs(3)}
          onClick={() => handleFilter("ApprovalCompleted")}
          isActive={activeMemo === 3}
        />

        <PageCards
          cardTitle="Total PV Declined"
          count={cardCounts.declined !== null ? cardCounts?.declined : <Spin />}
          // onClick={() => filterLogs(4)}
          onClick={() => handleFilter("Rejected")}
          isActive={activeMemo === 4}
        />



        {/* <PageCards
          cardTitle="Total PV Saved"
          count={
            cardCounts?.saved?.length}
          // onClick={() => filterLogs(1)}
          onClick={() => filterPV('Saved')}
          isActive={activeMemo === 1}
        /> */}
      </div>

      {
        <div className="flex space-between w-100 m-b-20">
          <PageCards
            cardTitle="Total Liabilities"
            // count={liability ? `₦${numberWithCommas(Number(liability?.liability).toFixed(2)) || 0}` : <Spin />}
            count={"N/A"}
            // onClick={() => filterLogs(4)}
            // onClick={() => filterPV('Paid')}
            isActive={activeMemo === 4}

          />
          <PageCards
            cardTitle="Pending PV Liabilities"
            count={
              liability ? `₦${numberWithCommas(Number(liability?.totalPending).toFixed(2)) || 0}` : <Spin />}
            // onClick={() => filterLogs(1)}
            // onClick={() => filterPV('Pending')}
            isActive={activeMemo === 1}
          />
          <PageCards
            cardTitle=" Pending PV - Capital Liabilities"
            count={otherLiability ? `₦${numberWithCommas(Number(otherLiability?.capital).toFixed(2))}` : <Spin />}
            // onClick={() => filterLogs(4)}
            // onClick={() => filterPV('Paid')}
            isActive={activeMemo === 4}
          />
          <PageCards
            cardTitle=" Pending PV - Recurrent Liabilities"
            count={
              otherLiability ? `₦${numberWithCommas(Number(otherLiability?.recurrent + otherLiability?.salary + otherLiability?.overhead).toFixed(2))}` : <Spin />}
            // onClick={() => filterLogs(1)}
            // onClick={() => filterPV('Pending')}
            isActive={activeMemo === 1}
          />
        </div>

      }

      {
        <div>
          <h1>BANK BALANCE: {bankBalance ? `₦${numberWithCommas(String(bankBalance))}` : <Spin />} </h1>
          {/* <button className='btn btn-large' onClick={handleBankBalanceModal}>Enter Current Bank Balance</button> */}
        </div>
      }


      <div className="w-100 m-t-20">
        {/* <table className="table-view m-t-10">
          <thead className="table-header">
            <tr className="table-row">
              <th
                className="th"
                style={{ textAlign: 'left', fontWeight: 'bolder' }}
              >
                <p>Transaction History</p>
              </th>
              <th className="th"></th>
              <th className="th actions">
                <p>Action</p>
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {managementRequestDataFixed &&
              sortByLastUpdated(managementRequestDataFixed).map((item) => (
                <ListItem key={item.id} details={item} />
              ))}
          </tbody>
        </table> */}

        {/* {totalElement > limit && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              showSizeChanger
              pageSize={limit}
              onShowSizeChange={paginationChangeHandler}
              current={current}
              total={totalElement}
              // showTotal={true}
              onChange={paginationChangeHandler}
              // pageSizeOptions={pageSizeOptions}
              responsive
            />
          </div>
        )}
        {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
        {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>} */}
      </div>


      <div className='flex w-100 space-between m-b-10'>
        <button onClick={() => setShowPrintModal(!showPrintModal)} className='btn w-25'><img className='m-r-10' src={xls} alt="" />DOWNLOAD</button>


        <div className='w-60 flex space-between'>
          <div className='flex-basis-48'><Select options={queryOptions} defaultValue={{ value: 1, label: "All" }} onChange={handleChangeQuery} /></div>
          <div className='flex-basis-48'><SearchInput
            onChange={(e) => { handleSearch(e) }}
            className="w-100 p-5"

          />
          </div>


        </div>

      </div>

      <div>
        <Table
          className='ww-100'
          bordered
          dataSource={table && table}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ x: 400 }}
          loading={{
            indicator: <div> <Spin /> </div>, spinning: tableLoading
          }}
        />

        {paginationLength > 0 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              showSizeChanger
              pageSize={pageSize}
              onShowSizeChange={paginationChangeHandler}
              current={pageNumber}
              total={paginationLength}
              // showTotal={true}
              onChange={paginationChangeHandler}
              // pageSizeOptions={pageSizeOptions}
              responsive
            />
          </div>
        )}
      </div>

      {voucherList?.length > 0 && <div className='flex space-between'>
        <button onClick={() => onYesNoAlert(handlePayMultiple, null, "Are You Sure ?")} className="btn w-48 m-t-20">Submit All</button>
        <button onClick={() => onYesNoAlert(declinePayMultiple, null, "Are You Sure ?")} className="btn-red w-48 m-t-20">Decline All</button>

      </div>}



      {
        showPictureModal && <DocumentModal closeModal={closeModal} paymentVoucher={modalData.voucher} schedule={modalData.schedule} type={documentType} />
      }

      {
        showPrintModal && <PrintModal count={paginationLength} closeModal={togglePrintModal} />
      }

      {
        showReviewForm && <ReviewForm closeModal={toggleReviewModal} approve={confirmApprove} details={details} type={"approve"} />
      }

    </div >
  );
};

const resolveStatus = (pending, approved, rejected) => {
  if (approved) return 'status_active';
  if (rejected) return 'status_rejected';
  if (pending) return 'status_pending';
  else return 'status_draft';
};

const resolveStatusName = (pending, approved, rejected, unassisgned) => {
  if (approved) return 'Approved';
  if (rejected) return 'Rejected';
  if (pending) return 'Pending';
  if (unassisgned) return 'Draft';
  else return 'pending';
};

const ListItem = ({ details = {} }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr
        className={`${showDetails && 'active'} table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: '20px 10px', width: '60%' }}>
          {details.requestTitle}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          <div className="flex flex-v-center">
            <div
              className={`status_badge ${resolveStatus(
                details.isPending,
                details.isApprovalCompleted,
                details.isRejected
              )}`}
            ></div>{' '}
            {resolveStatusName(
              details.isPending,
              details.isApprovalCompleted,
              details.isRejected,
              details.isUnAssigned
            )}
          </div>
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          <b>Last treated:</b>
          {details.lastUpdated &&
            moment(details.lastUpdated).format('MMM Do YY')}
        </td>
      </tr>
      {showDetails && (
        <tr className="">
          <td colSpan={5}>
            {/* <TrainingRequestDetails
              owner
              cardDetails={details}
              style={{ transition: "all 0.6s ease-in" }}
            /> */}
          </td>
        </tr>
      )}
    </>
  );
};

export default FinaneReview;
