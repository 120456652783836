/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import { RiBarChartFill, RiSettings2Fill, RiUserSettingsLine } from 'react-icons/ri';
import { BiEnvelope } from 'react-icons/bi';
import { FaMoneyBillWave } from 'react-icons/fa';
import { HiOutlineUserAdd } from 'react-icons/hi';
import { CgUserList } from 'react-icons/cg';
import Cookies from 'js-cookie';
import { GiCash } from "react-icons/gi";

export default [
  { title: 'Home', href: '/staff-review', icon: <RiBarChartFill className="icon" /> },
  { title: 'Finance Dashboard', href: '/staff-review/finance-dashboard/', icon: <FaMoneyBillWave className="icon" /> },
  { title: 'Finance Approval', href: '/staff-review/finance-approval/', icon: <FaMoneyBillWave className="icon" /> },
  { title: 'Budget Performance', href: '/staff-review/budget-performance/', icon: <GiCash className="icon" /> },
  { title: 'Finance Approval Report', href: '/staff-review/Approver-report/', icon: <GiCash className="icon" /> },
  // { title: 'Raise a Memo', href: '/staff-review/raise-memo', icon: <BiEnvelope className="icon" /> },
  
  { title: 'Settings', href: '/staff-review/settings', icon: <RiSettings2Fill className="icon" /> },
  { title: 'Logout', href: `/staff-review/logout`, icon: <RiUserSettingsLine className="icon" /> }
];
