import React, { useState } from 'react';


const tableHeaders = [
    'Department',
    "Approver's Name",
    'Payment Voucher Comments',
    'Schedule Comments'
];

const ChequeModal = ({
    paymentVoucher,
    schedule,
    type
}) => {
    const [showPictureModal, setShowPictureModal] = React.useState(false);
    const [modalData, setModalData] = useState({});

    const closeModal = () => {
        setShowPictureModal(false);
    };

    const displayComments = () => {
        if (paymentVoucher.voucherType === "Internal") {
            return <tbody className="table-body">
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        DFA
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.dfa}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.dfaComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.dfaComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Internal Auditor
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.auditor}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.auditComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.auditComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        PS
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.ps}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.psComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.psComment}
                    </td>
                </tr>




            </tbody>

        }
        else if (paymentVoucher.voucherType === "Salary Voucher") {
            return <tbody className="table-body">
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        DFA
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.dfa}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.dfaComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.dfaComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Internal Auditor
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.auditor}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.auditComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.auditComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Final Account
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.finalAcct}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.finalAcctComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.finalAcctComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Inspectorate
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.inspectorate}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.inspectorateComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.inspectorateComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Expenditure Control
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.expControl}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.expControlComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.expControlComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Accountant General
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.ag}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.agComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.agComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Management Account
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.mgtAcct}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.mgtAcctComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.mgtAcctComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Treasury Cash Account
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.tca}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.tcaComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.tcaComment}
                    </td>
                </tr>
            </tbody>

        }
        else {
            return <tbody className="table-body">
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        DFA
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.dfa}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.dfaComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.dfaComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Internal Auditor
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.auditor}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.auditComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.auditComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Final Account
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.finalAcct}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.finalAcctComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.finalAcctComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Expenditure Control
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.expControl}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.expControlComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.expControlComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Accountant General
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.ag}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.agComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.agComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Management Account
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.mgtAcct}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.mgtAcctComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.mgtAcctComment}
                    </td>
                </tr>
                <tr role="presentation">
                    <td className="td p-r td-bordered">
                        Treasury Cash Account
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.tca}
                    </td>
                    <td className="td p-r td-bordered">
                        {paymentVoucher?.tcaComment}
                    </td>
                    <td className="td p-r td-bordered">
                        {schedule?.tcaComment}
                    </td>
                </tr>
            </tbody>
        }
    }

    return (
        <div className="w-100 m-20">
            {
                type === 'comment' ?
                    <div className="w-100 p-x-10 p-y-10 space-between flex-v-center">
                        <div className="w-100 flex bg_green p-x-10 p-y-10">Department Comments</div>
                        <div className="table-view">
                            <table className="table-view">
                                <thead className="">
                                    <tr className="tr-bordered">
                                        {tableHeaders.map((th, index) => (
                                            <th key={index} className="th th-bordered">
                                                <p>{th}</p>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {displayComments()}
                            </table>
                        </div>
                    </div>

                    :
                    <button className='btn btn-primary m-t-20 flex-v-center' onClick={() => setShowPictureModal(!showPictureModal)}>
                        Show Cheque
                    </button>}



            {
                showPictureModal && <div>
                    <img src={paymentVoucher?.chequeUrl} alt="cheque" style={{ width: "21.7cm" }} />
                    <p className='m-t-20'>
                        Not an image?
                    </p>
                    <a href={paymentVoucher?.chequeUrl} target="_blank" rel="noopener noreferrer">Download here</a>
                </div>


            }

        </div>
    )
}
export default ChequeModal;