import Cookies from 'js-cookie';
/* eslint-disable linebreak-style */
// import jwt from 'jsonwebtoken';
// import crypto from 'crypto';

// /**
//  * This function encodes its payload into a token
//  * @param {object || string} payload the value to be encoded
//  * @param {string} name the name that will be stored in the localStorage
//  */
// export const encodeToken = (payload, name) => {
//   const token = jwt.sign(payload, process.env.REACT_APP_JWT_SECRET);

//   localStorage.setItem(name, token);
// };

// /**
//  * this function decodes the token value for the name supplied if it exists
//  * @param {string} tokenName the token to be decoded
//  * @param {string} index index url that will be redirected to if the decoding process fails
//  * @param {boolean} logoutUser indicates if the user should be taken back to the index page or not
//  */
// export const decodeToken = (tokenName, index = '/', logoutUser = true) => {
//   try {
//     const item = localStorage.getItem(tokenName);
//     if (!item) {
//       logoutUser && logout(index);
//       return null;
//     }
//     return jwt.verify(item, process.env.REACT_APP_JWT_SECRET);
//   } catch (err) {
//     console.log(err);
//     logoutUser && logout(index);
//   }
//   return null;
// };

// /**
//  * This function hashes a password and returns the hash in hex format.
//  * @param {string} password the password that you want to hash
//  * @returns {string} string value of the hash in hex format
//  */
// export const hashPassword = (password) => crypto
//   .createHash('sha512').update(password).digest('hex');

export const logout = async () => {
  const link = localStorage.getItem('homeLink')
  sessionStorage.removeItem('token');
  localStorage.removeItem("requisitionNumber")
  localStorage.removeItem("approvedAmount")
  localStorage.removeItem("totalAmount")
  localStorage.removeItem("expenseType")
  localStorage.removeItem('USER_INFO');
  localStorage.removeItem('homeLink');
  localStorage.removeItem("requisitionNumber")
  localStorage.removeItem("approvedAmount")
  localStorage.removeItem("totalAmount")
  localStorage.removeItem("expenseType")
  Cookies.set('tokenExist', false);
  Cookies.set('fullname', null);
  Cookies.set('role', null);

  window.location.assign(link || '/');
};
