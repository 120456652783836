export const URLAPI = {
  Auth: {
    Post: "/api/Auth/authorization",
    PostId: "/api/Auth/signin",
  },
 Dashboard:{
   Stats:"/dashboardstats",
   MdaList:"/listofmdas",
   MdaStaff:"/mdastaff",
   Personal:"/staffappraisal",
   MdaStats:"/mdastats",
   Qualifications:"/staffqualifications",
   SelfTraining:"/staffselftraining",
   ApprovedTraining:"/staffapprovedtraining",
   DetailedAppraisal:"/staffdetailedappraisal"
 }
};
