import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// import { decodeToken } from '../../utility/auth';

const LocationDetails = ({ details = {} }) => {
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  // useEffect(() => {
  //   const r = localStorage.getItem('r');
  //   let fn = localStorage.getItem('fn');
  //   let ln = localStorage.getItem('ln');

  //   r && setRole(decodeToken('r').r);
  //   if (fn && ln) {
  //     fn = decodeToken('fn').fn;
  //     ln = decodeToken('ln').ln;
  //     setUsername(`${fn} ${ln}`);
  //   }
  // }, []);

  return (
    <div className="greeting">
      <p className="m-b-5">
        Good Day
      </p>
      <h3 className="m-b-5">{Cookies.get('fullName') || 'N/A'}</h3>
      <p className="" style={{ fontSize: '0.75rem' }}>
        {Cookies.get('role') || 'N/A'}
        ,
      </p>
    </div>
  );
};

export default LocationDetails;
