import React, { useEffect, useState } from 'react';
// import { RiAddLine } from 'react-icons/ri';

import ProfilePix from '../../assets/images/profile-pix.jpg';
import { URLAPI } from '../../utility/ApiMethods';
import { Get } from '../../utility/fetch';
import FormSearchInput from '../inputs/FormSearchInput';
import TextInput from '../inputs/TextInput';
import LocationDetails from '../layouts/LocationDetails';
import Qualifications from '../layouts/QualificationsHeld';
import TrainingDetails from '../layouts/TrainingDetails';


const EmployeeDetails = (props) => {
  const [qualifications, setQualifications] = useState([]);
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);
  const [tabActivity1, setTabActivity1] = useState("active")
  const [tabActivity2, setTabActivity2] = useState("")
  const [tabActivity3, setTabActivity3] = useState("")
  const [tabActivity4, setTabActivity4] = useState("")
  const [tabStyles, setTabStyles] = useState("left")
  const [selfTraining, setSelfTraining] = useState([])
  const [approvedTraining, setApprovedTraining] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Employee Details';
  }, []);




  useEffect(()=>{
    handleTab1();
  }, [props.reset])
 
 const getTab2 =()=>{
  Get(URLAPI.Dashboard.Qualifications, handleQualifications, props.employeeRecord.id)
 }


  const handleQualifications =(data)=>{
    setQualifications(data.result);
    handleTab2()
  }


const getTab3 =()=>{
  Get(URLAPI.Dashboard.SelfTraining, handleSelfTraining, props.employeeRecord.id)
  Get(URLAPI.Dashboard.ApprovedTraining, handleApprovedTraining, props.employeeRecord.id)
}

const handleSelfTraining = (data) =>{
  setSelfTraining(data.result)
}

const handleApprovedTraining = (data) =>{
  setApprovedTraining(data.result)
  handleTab3()
}


const handleTab1 = () =>{
  setTabStyles("left")
  setTabActivity1("active")
  setTabActivity2("")
  setTabActivity3("")
  setTabActivity4("")
  setTab1(true)
  setTab2(false)
  setTab3(false)
  setTab4(false)
}

const handleTab2 = () =>{
  setTabStyles("w-100")
  setTabActivity1("")
  setTabActivity2("active")
  setTabActivity3("")
  setTabActivity4("")
  setTab1(false)
  setTab2(true)
  setTab3(false)
  setTab4(false)
}

const handleTab3 = () =>{
  setTabStyles("w-100")
  setTabActivity1("")
  setTabActivity2("")
  setTabActivity3("active")
  setTabActivity4("")
  setTab1(false)
  setTab2(false)
  setTab3(true)
  setTab4(false)
}

// const handleTab4 = () =>{
//   setTabStyles("w-100")
//   setTabActivity1("")
//   setTabActivity2("")
//   setTabActivity3("")
//   setTabActivity4("active")
//   setTab1(false)
//   setTab2(false)
//   setTab3(false)
//   setTab4(true)
// }




console.log(props.employeeRecord)
  return (
    <div className="w-100">
      {/* <div className="w-100 flex space-between flex-v-center">
        <h2>Global System Administrator</h2>
        <LocationDetails />
      </div> */}
      {/* <div className="w-100 flex space-between m-t-20 flex-v-center">
        <h4 className="san-sherif">Staff Onboarding</h4>
      </div> */}
      <div className="w-100 m-t-20 user-full-details">
        <div className="tab-btns w-100">
          <button onClick = {handleTab1} type="button" className={tabActivity1} style={{cursor:'pointer'}}>Personal Particulars</button>
          <button onClick = {getTab2} type="button" className={tabActivity2} style={{cursor:'pointer'}}>Qualifications Held</button>
          <button onClick = {getTab3} type="button" className={tabActivity3} style={{cursor:'pointer'}}>Trainings & Development</button>
          {/* <button onClick = {handleTab4} type="button" className={tabActivity4} style={{cursor:'pointer'}}>Leave Records</button> */}
          {/* <button type="button" className="">Profession</button>
          <button type="button" className="">Training</button>
          <button type="button" className="">Work Experience</button>
          <button type="button" className="">Referee/Guarantor</button>
          <button type="button" className="">Access/Privileges</button> */}
        </div>
        <div className="w-100 flex">
          <div className={tabStyles}>
            {
            
            tab1 ?
     
            <div className="content w-100 white-color">
              <div className="w-100 official-details">
                {/* <FormSearchInput className="w-100 m-b-10" label="Email Address" /> */}
                <div className="row">
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.staffNumber} className="w-100 m-b-10" label="Staff ID" />
                  </div>
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.title} className="w-100 m-b-10" label="Title" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.lastname} className="w-100 m-b-10" label="Surname" />
                  </div>
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.firstname} className="w-100 m-b-10" label="First Name" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.firstAppCadre} className="w-100 m-b-10" label="Post to which first appointed" />
                  </div>
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.dateOfConfirmation} className="w-100 m-b-10" label="Date of Confirmation" />
                  </div>
                </div>
                {/* <TextInput className="w-100 m-b-10" label="Assigned MDA" /> */}
                <div className="row">
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.designation} className="w-100 m-b-10" label="Present Substantive Post" />
                  </div>
                  <div className="col-6 no-margin">
                    <TextInput readOnly value={props.employeeRecord.dateOfPresentPosition} className="w-100 m-b-10" label="Date Appointed to Substantive Post" />
                  </div>
                </div>

                    <TextInput readOnly value = {new Date().toISOString().substr(0,10)} className="w-100 m-b-10" label="Date of Acting Appointment during the period covered by the Report" />
                       
                {/* <div className="row">
                  <div className="col-6 no-margin">
                    <button type="button" className="btn btn-light w-100 btn-large">Update Form</button>
                  </div>
                  <div className="col-6 no-margin">
                    <button type="button" className="btn w-100 btn-large">Publish Profile</button>
                  </div>
                </div> */}
              </div>
            
            </div>
              : (tab2) 
              ?
             
                <div className=" w-100">
                  <Qualifications staffQualifications = {qualifications}/>
                  </div>
                :(tab3)?
                <div className=" w-100 white-color">
                    <TrainingDetails selfTraining = {selfTraining} approvedTraining={approvedTraining}/>
                </div>
                : <div>

                </div>
              

            }
            
          </div>

            {
              tab1 &&
          <div className="right white-color">
            {/* <button type="button" className="btn btn-round m-b-10 flex btn-light flex-v-center">
              <RiAddLine />
              Create/Add New Category
            </button>
            <button type="button" className="btn btn-round m-b-10 flex btn-light flex-v-center">
              <RiAddLine />
              Add New Sub Category
            </button>
            <button type="button" className="btn btn-round m-b-10 flex btn-light flex-v-center">
              <RiAddLine />
              Add Field
            </button> */}
            <div className="w-100 m-t-40 center-text profile-brief-square ">
              <img src={props.employeeRecord.profilePicture} alt="profile avater" />
              {/* <div className="brief m-t-20">
                <h4>Adeleke Ogunlanrefemi</h4>
                <hr />
                <p className="m-b-10">
                  <b>Engagement Date:&nbsp;</b>
                  August 1, 2019
                </p>
                <p className="m-b-10">
                  <b>Duration on Grade:&nbsp;</b>
                  30 months
                </p>
              </div> */}
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
