import { Button } from "antd";
import React from "react";
import * as XLSX from "xlsx";

const ExcelExportButton = ({ data, fileName, sheetName, headings }) => {
  //   const headings = [
  //     'Capital Budget',
  //     'Amount Spent (Capital)',
  //     'Amount Spent (Jan)',
  //     'Amount Spent (Feb)',
  //     'Amount Spent (Mar)',
  //     'Amount Spent (Apr)',
  //     'Amount Spent (May)',
  //     'Amount Spent (Jun)',
  //     'Amount Spent (Jul)',
  //     'Amount Spent (Aug)',
  //     'Amount Spent (Sep)',
  //     'Amount Spent (Oct)',
  //     'Amount Spent (Nov)',
  //     'Amount Spent (Dec)',
  //     'Percentage of Capital',
  //     'MDA'
  //   ];

  const worksheetDataw = [
    headings,
    ...data.map((item) => [
      item.mda,
      item.capitalBudget || item.overHeadBudget || item.personnelBudget,
      item.amountSpentCapital ||
        item.amountSpentOverHead ||
        item.amountSpentPersonnel,
      item.percentageCapital ||
        item.percentageOverHead ||
        item.percentagePersonnel,

      item.amountSpentJan,
      item.amountSpentFeb,
      item.amountSpentMar,
      item.amountSpentApr,
      item.amountSpentMay,
      item.amountSpentJun,
      item.amountSpentJul,
      item.amountSpentAug,
      item.amountSpentSep,
      item.amountSpentOct,
      item.amountSpentNov,
      item.amountSpentDec,
    ]),
  ];

  console.log(worksheetDataw);

  const exportToExcel = () => {
    const worksheetData = [
      //   headings,
      //   ...data.map((item) => [
      //     item.mda,
      //     item.capitalBudget||item.overHeadBudget||item.personnelBudget,
      //     item.amountSpentCapital||item.amountSpentOverHead||item.amountSpentPersonnel,
      //     item.percentageCapital||item.percentageOverHead||item.percentagePersonnel,

      //     item.amountSpentJan,
      //     item.amountSpentFeb,
      //     item.amountSpentMar,
      //     item.amountSpentApr,
      //     item.amountSpentMay,
      //     item.amountSpentJun,
      //     item.amountSpentJul,
      //     item.amountSpentAug,
      //     item.amountSpentSep,
      //     item.amountSpentOct,
      //     item.amountSpentNov,
      //     item.amountSpentDec,
      ...worksheetDataw,
      //   ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  //   console.log(data);

  return <Button  type='primary' style={{
    // backgroundColor: "#1C811C",
    color: "white",
  }} onClick={exportToExcel}>Export to Excel</Button>;
};

export default ExcelExportButton;
